import React, { useEffect, useState } from "react";
import { Button, Modal, Form, ListGroup } from "react-bootstrap";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { useAppServices } from "../../../../../../hook/services";

const OpportunityTabsModal = ({ sso_cred, isgroup, handleMenus, editData }) => {
  const AppService = useAppServices();
  const [show, setShow] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [showAddTab, setShowAddTab] = useState(false);
  const [trueUrl, setTrueUrl] = useState(false);
  const [tabFormData, setTabFormData] = useState({
    name: "",
    link: "",
    sendContact: true,
    contact: false,
    address: false,
    customFields: true,
    sendType: "Query String",
  });
  const [editIndex, setEditIndex] = useState(-1);

  const fetchData = async () => {
    try {
      if (isgroup) {
        setTabs(editData);
      } else {
        const { response } = await AppService.opportunities.get({
          query: `company_id=${sso_cred.companyId}`,
        });
        setTabs(response?.data[0]?.tabs || []);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [sso_cred]);

  const handleClose = () => {
    setTrueUrl(false);
    setShow(false);
    setShowAddTab(false);
    resetForm();
    setEditIndex(-1);
  };

  const handleShow = () => setShow(true);
  const handleAddTab = () => setShowAddTab(true);

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setTabFormData({
      ...tabFormData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSaveTab = async () => {
    if (!tabFormData.link || !tabFormData.link.startsWith("https://")) {
      document.getElementById("formLink").style.borderColor = "red";
      setTrueUrl(true);
      return;
    }
    setTrueUrl(false);
    document.getElementById("formLink").style.borderColor = "#E2E5EC";

    const updatedTabs = editIndex > -1 ? [...tabs] : [...tabs, tabFormData];
    if (editIndex > -1) {
      updatedTabs[editIndex] = tabFormData;
    }

    setTabs(updatedTabs);
    const payload = {
      company_id: sso_cred.companyId,
      tabs: updatedTabs,
    };

    try {
      if (isgroup) {
        handleMenus(tabFormData, "opportunity_tabs", editIndex > -1 ? "edit" : "add", editIndex);
      } else {
        await AppService.opportunities.create({ payload });
      }
    } catch (error) {
      console.error("Error saving tab:", error);
    }

    setShowAddTab(false);
    resetForm();
  };

  const deleteTab = async (indexToDelete) => {
    const updatedTabs = tabs.filter((_, index) => index !== indexToDelete);
    setTabs(updatedTabs);

    const payload = {
      company_id: sso_cred.companyId,
      tabs: updatedTabs,
    };

    try {
      if (isgroup) {
        handleMenus(null, "opportunity_tabs", "delete", indexToDelete);
      } else {
        await AppService.opportunities.create({ payload });
      }
    } catch (error) {
      console.error("Error deleting tab:", error);
    }
  };

  const editTab = (indexToEdit) => {
    setShowAddTab(true);
    setEditIndex(indexToEdit);
    setTabFormData(tabs[indexToEdit]);
  };

  const resetForm = () => {
    setTabFormData({
      name: "",
      link: "",
      sendContact: true,
      contact: false,
      address: false,
      customFields: true,
      sendType: "Query String",
    });
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Opportunity Tabs
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Opportunity Tabs</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ height: "50vh", overflowY: "auto" }}>
          {showAddTab ? (
            <Form>
              <Form.Group controlId="tabName">
                <Form.Label>Tab name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={tabFormData.name}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formLink">
                <Form.Label>Form link</Form.Label>
                <Form.Control
                  type="text"
                  name="link"
                  value={tabFormData.link}
                  onChange={handleInputChange}
                />
                {trueUrl && (
                  <small style={{ color: "red" }}>Enter a valid url</small>
                )}
              </Form.Group>
              <Form.Group controlId="sendContactData">
                <Form.Check
                  type="checkbox"
                  name="sendContact"
                  label="Send contact data to URL"
                  checked={tabFormData.sendContact}
                  onChange={handleInputChange}
                />
                <Form.Check
                  type="checkbox"
                  name="customFields"
                  label="Send Custom Fields data to URL"
                  checked={tabFormData.customFields}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Form>
          ) : (
            <>
              {tabs.length > 0 ? (
                <ListGroup>
                  {tabs.map((tab, index) => (
                    <ListGroup.Item
                      key={index}
                      className="d-flex justify-content-between align-items-center"
                    >
                      {tab.name}
                      <div className="d-flex gap-2">
                        <FaEdit
                          cursor={"pointer"}
                          onClick={() => editTab(index)}
                        />
                        <FaTrashAlt
                          color="red"
                          cursor={"pointer"}
                          onClick={() => deleteTab(index)}
                        />
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              ) : (
                <div className="text-center">
                  <h5>No tabs found</h5>
                </div>
              )}
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          {showAddTab ? (
            <>
              <Button
                variant="secondary"
                onClick={() => {
                  setShowAddTab(false);
                  setTrueUrl(false);
                  const formLinkElement = document.getElementById("formLink");
                  if (formLinkElement) {
                    formLinkElement.style.borderColor = "#E2E5EC";
                  }
                }}
              >
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSaveTab}>
                Save
              </Button>
            </>
          ) : (
            <Button variant="primary" onClick={handleAddTab}>
              + Add
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OpportunityTabsModal;
