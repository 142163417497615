import React, { useEffect, useState } from "react";
import { Button, Modal, Form, ListGroup } from "react-bootstrap";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { useAppServices } from "../../../../../../hook/services";

const SettingMenuModel = ({ sso_cred, isgroup, handleMenus, editData }) => {
  const AppService = useAppServices();

  const [show, setShow] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [showAddTab, setShowAddTab] = useState(false);
  const [trueUrl, setTrueUrl] = useState(false);
  const [tabFormData, setTabFormData] = useState({
    name: "",
    link: "",
    sendContact: false,
    type: "",
  });
  const [editIndex, setEditIndex] = useState(-1);

  const fetchData = async () => {
    if (isgroup) {
      setTabs(editData);
    } else {
      const { response } = await AppService.setting_menu.get({
        query: `company_id=${sso_cred.companyId}`,
      });
      setTabs(response?.data[0]?.tabs || []);
    }
  };

  useEffect(() => {
    fetchData();
  }, [sso_cred]);

  const handleClose = () => {
    setTrueUrl(false);
    setShow(false);
    setShowAddTab(false);
    setTabFormData({
      name: "",
      link: "",
      id: "",
      type: "",
    });
    setEditIndex(-1);
  };

  const handleShow = () => setShow(true);
  const handleAddTab = () => setShowAddTab(true);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTabFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveTab = async () => {
    if (tabFormData.type === "Link" && (!tabFormData.link || !tabFormData.link.startsWith("https://"))) {
      document.getElementById("formLink").style.borderColor = "red";
      setTrueUrl(true);
      return;
    }

    setTrueUrl(false);
    if (tabFormData.type === "Link") {
      document.getElementById("formLink").style.borderColor = "#E2E5EC";
    }

    const updatedTabs = [...tabs];
    if (editIndex > -1) {
      updatedTabs[editIndex] = tabFormData;
    } else {
      updatedTabs.push(tabFormData);
    }

    setTabs(updatedTabs);

    const payload = {
      company_id: sso_cred.companyId,
      tabs: updatedTabs,
    };

    if (isgroup) {
      handleMenus(tabFormData, "setting_menus", editIndex > -1 ? "edit" : "add", editIndex);
    } else {
      await AppService.setting_menu.create({ payload });
    }

    setShowAddTab(false);
    setTabFormData({
      name: "",
      link: "",
      id: "",
      type: "",
    });
    setEditIndex(-1);
  };

  const deleteTab = async (indexToDelete) => {
    const updatedTabs = tabs.filter((_, index) => index !== indexToDelete);
    setTabs(updatedTabs);

    const payload = {
      company_id: sso_cred.companyId,
      tabs: updatedTabs,
    };

    if (isgroup) {
      handleMenus(null, "setting_menus", "delete", indexToDelete);
    } else {
      await AppService.setting_menu.create({ payload });
    }
  };

  const editTab = (indexToEdit) => {
    setShowAddTab(true);
    setEditIndex(indexToEdit);
    setTabFormData(tabs[indexToEdit]);
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Setting Menu
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Setting Side Menu</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ height: "50vh", overflowY: "auto" }}>
          {showAddTab ? (
            <Form>
              <Form.Group controlId="tabName">
                <Form.Label>Tab name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={tabFormData.name}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formid">
                <Form.Label>Css ID  <br/>
                <strong> <small> (Ensure the ID is unique and does not conflict with other element IDs.)</small>
                </strong> </Form.Label>
                <Form.Control
                  type="text"
                  name="id"
                  value={tabFormData.id}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formActionName">
                <Form.Label>Action Type</Form.Label>
                <Form.Select
                  name="type"
                  value={tabFormData.type}
                  onChange={handleInputChange}
                >
                  <option value="">Select an action</option>
                  <option value="Target">Divider</option>
                  <option value="Link">Menu</option>
                </Form.Select>
              </Form.Group>

              {tabFormData.type === "Link" && (
                <Form.Group className="mb-3" controlId="formLink">
                  <Form.Label>Link</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Paste link here"
                    name="link"
                    value={tabFormData.link}
                    onChange={handleInputChange}
                  />
                  {trueUrl && (
                    <p id="validLink" style={{ color: "red" }}>
                      Please Enter a valid Url
                    </p>
                  )}
                </Form.Group>
              )}
            </Form>
          ) : (
            <>
              {tabs.length > 0 ? (
                <ListGroup>
                  {tabs.map((tab, index) => (
                    <ListGroup.Item
                      key={index}
                      className="d-flex justify-content-between align-items-center"
                      style={{
                        borderRadius: "8px",
                        border: "1px solid #dee2e6",
                        marginBottom: "10px",
                        padding: "10px",
                        backgroundColor: "#f8f9fa",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            color: "#333",
                          }}
                        >
                          {tab.name}
                        </div>
                        <div
                          style={{
                            fontSize: "14px",
                            color: "#6c757d",
                            marginTop: "4px",
                          }}
                        >
                          {tab.type === "Target" ? "Divider" : "Menu Item"}
                        </div>
                      </div>
                      <div className="d-flex gap-2">
                        <FaEdit
                          cursor={"pointer"}
                          onClick={() => editTab(index)}
                          style={{ color: "#007bff", fontSize: "18px" }}
                        />
                        <FaTrashAlt
                          color="red"
                          cursor={"pointer"}
                          onClick={() => deleteTab(index)}
                          style={{ fontSize: "18px" }}
                        />
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              ) : (
                <div className="text-center">
                  <h5>No tabs found</h5>
                </div>
              )}
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          {showAddTab ? (
            <>
              <Button
                variant="secondary"
                onClick={() => {
                  setShowAddTab(false);
                  setTrueUrl(false);
                  const formLinkElement = document.getElementById("formLink");
                  if (formLinkElement) {
                    formLinkElement.style.borderColor = "#E2E5EC";
                  }
                }}
              >
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSaveTab}>
                Save
              </Button>
            </>
          ) : (
            <Button variant="primary" onClick={handleAddTab}>
              + Add
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SettingMenuModel;
