import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import QueryString from 'qs'
import { ReqMethods, ghl } from '../../../enums'
import { useAppServices } from '../../../hook/services'
// import { useAgencyInfo } from 'context/agency'
// import { AgencyContext } from '../../../context/Agency.context'

function GHL() {
  const navigate = useNavigate()
  const { search } = useLocation()
  const { app_id } = useParams()
  const code = new URLSearchParams(search).get('code')
  const state = new URLSearchParams(search).get('state')
  const AppServices = useAppServices()
  const [checkapi, setcheckapi] = useState(false)
  const [Error, setError] = useState('')
  const [app_data, setapp_data] = useState({})
  var called = false

  const associate = async (creds, app) => {
    const payload = {
      company_id: creds.companyId,
      location_id: creds?.locationId,
      access_token: creds.access_token,
      refresh_token: creds.refresh_token,
      app_id: app_id,
      main_app_id: state,
    }
    console.log(payload, 'payload')

    const { response } = await AppServices.app_token.nested_app({ payload })
    console.log(response, 'response')
    console.log(app?.app_page_url, 'app?.app_page_url')

    if (!response) return setError('Something went wrong while setup the things, please try latter')
    // update(payload)
    return setError('locations and users get successfully')
  }

  // const getLocation = async (creds) => {
  //   const payload = {
  //     method: ReqMethods.GET,
  //     path: `${ghl.APIs.v2.location(creds.locationId)}`,
  //     key: creds.access_token,
  //     refresh_token: creds.refresh_token,
  //   }
  //   // console.log(payload)
  //   const { response } = await AppServices.services.ghl.call_service({ payload })

  //   if (!response) return setError('Something went wrong while integration, please try latter')
  //   // console.log(response.data)

  //   return response && associate(response.data, creds)
  // }

  const getAppData = async () => {
    const { response } = await AppServices.apps.filter({
      query: `nested_app.app_id=${app_id}`,
    });
    if (response) {
      // setapp_data(response.data)
      getCreds(response.data)
    }
  }

  const getCreds = async (app) => {
    const payload = {
      method: ReqMethods.POST,
      path: ghl.APIs.oauth,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      payload: QueryString.stringify({
        client_id: app?.nested_app?.client_id,
        client_secret: app?.nested_app?.client_secret,
        grant_type: 'authorization_code',
        code,
        refresh_token: '',
      }),
    }
    console.log(payload)
    const { response } = await AppServices.services.ghl.call_service({ payload })

    if (!response) return setError('Something went wrong while setup the things, please try latter')
    // if(location_id !== response.data.locationId) return setError('location ID mismatch')
    console.log(response.data)
    return response && associate(response.data, app)
  }

  const onLoad = () => {
    console.log('called')
    if (!called) {

      getAppData()
      called = true
    }
  }

  useEffect(onLoad, [])

  return (
    <div>
      {!Error && <div>We are setup the things getting the locations and users...</div>}
      {Error && (
        <div>
          {Error}
          {/* <button onClick={() => navigate(-2)}>Go Back</button> */}
        </div>
      )}
    </div>
  )
}

export default GHL
