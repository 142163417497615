import React, { useEffect, useState } from "react";
import AppHeader from "../../AppHeader";
import { useAppServices } from "../../../hook/services";

import ContactButtons from "./components/ContactButtons";

import { useParams } from "react-router-dom";
import SSOHandler from "../ssoHandler";
import Loader from "../../../components/Loader";
import GroupLevel from "./components/GroupLevel";
import NewLevel from "./components/NewLevel";

const SidnavCustomizer = () => {
  const AppService = useAppServices();
  const { SSO, checkSSO } = SSOHandler();
  const { app_id } = useParams();

  const [sso_cred, setsso_cred] = useState({});
  const [app_data, setapp_data] = useState({});
  const [groups, setGroups] = useState([]);
  const [ghlLocations, setGhlLocations] = useState([]);
  const [loader, setloader] = useState(true);
  const getGroups = async (companyId) => {
    const { response } = await AppService.contact_buttons_group.get({
      query: `company_id=${companyId}`,
    });
    if (response) {
      console.log(response.data)
      setGroups(response.data)
      setGhlLocations(response.ghl_locations.map((location => ({
        ...location,
        label: location.name,
        value: location.id,
      }))))
    }
  }
  const getAppData = async () => {
    const { response } = await AppService.apps.filter({
      query: `app_id=${app_id}`,
    });
    if (response) {
      console.log(response.data)
      setapp_data(response.data)
      checkSSO(response.data)
    }
  }
  const onLoad = async () => {
    if (sso_cred?.companyId) {
      getGroups(sso_cred?.companyId)
    } else {

      getAppData();
    }
  };
  useEffect(() => {
    onLoad();
  }, []);
  useEffect(() => {
    if (SSO != '' && SSO != undefined) {
      var data = JSON.parse(SSO);
      if (data?.companyId) {
        getGroups(data?.companyId)
        setsso_cred(data)
        setloader(false)
      }

    }
  }, [SSO]);

  return (
    <>
      {
        loader ?
          <Loader />
          :
          <div>
            <AppHeader />
            <div className="main main-app p-lg-4">
              <div className="row mb-5">
                <div className="col-md-12">
                  <div className="d-flex gap-3 flex-wrap">
                    <div>
                      <ContactButtons sso_cred={sso_cred} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row gy-4">
                {
                  groups.map((group) =>

                    <div className="col-md-3">
                      <GroupLevel group={group} handleRefresh={onLoad} />
                    </div>
                  )
                }
                <div className="col-md-3">
                  <NewLevel sso_cred={sso_cred} handleRefresh={onLoad} ghlLocations={ghlLocations} />
                </div>
              </div>
            </div>
          </div>
      }
    </>
  );
};

export default SidnavCustomizer;
