import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { BsTrash } from "react-icons/bs";
import { IoMdEyeOff } from "react-icons/io";
import { FaEdit } from "react-icons/fa";
import { useAppServices } from "../../../../../../hook/services";

const companyId = "hEANpF0GDaTmISXjA8Fm";
function MenuEditorModal({ handleSave, type, data, sso }) {
  // alert(sso.companyId)
  const AppService = useAppServices();
  const [showModal, setShowModal] = useState(false);
  const [loader, setLoader] = useState(true);
  const [selectedItem, setSelectedItem] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [hideMode, setHideMode] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState("");
  const [label, setLabel] = useState("");
  const [editedLabels, setEditedLabels] = useState([]);
  const [hiddenItems, setHiddenItems] = useState([]);
  const [repositionMenu, setRepositionMenu] = useState(false);
  const [menuPlacement, setMenuPlacement] = useState("");
  const [menuTarget, setMenuTarget] = useState("");
  const [showIconSelector, setShowIconSelector] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [refresh, setrefresh] = useState(true);
  const [icon, setIcon] = useState(false);


  
  const [hidePermissionData, setHidePermissionData] = useState({
    user_permissions: {
      agency_admins: false,
      agency_users: false,
      account_admins: false,
      account_users: false,
    }
  });


  const handlePermissionsChange = (e) => {
    const { name, checked } = e.target;
    setHidePermissionData((prevState) => ({
      ...prevState,
      user_permissions: {
        ...prevState.user_permissions,
        [name]: checked, // Update the specific permission based on checkbox
      }
    }));
  };
  const handleIconClick = (icon) => {
    console.log("icon selected");
    setIcon(true);
    setSelectedIcon(icon);
    const iconcode = `&#x${icon.unicode};`;
    // setFormData({ ...formData, icons: iconcode });
    setShowIconSelector(false);
  };

  const handleIconInputChange = (event) => {
    setSearchTerm(event.target.value);
    // setShowIconSelector(true);
  };

  const icons = [
    { name: "address-book", unicode: "f2b9" },
    { name: "address-card", unicode: "f2bb" },
    { name: "adjust", unicode: "f042" },
    { name: "air-freshener", unicode: "f5d0" },
    { name: "align-center", unicode: "f037" },
    { name: "align-justify", unicode: "f039" },
    { name: "align-left", unicode: "f036" },
    { name: "align-right", unicode: "f038" },
    { name: "allergies", unicode: "f461" },
    { name: "ambulance", unicode: "f0f9" },
    { name: "american-signlanguage-interpreting", unicode: "f2a3" },
    { name: "anchor", unicode: "f13d" },
    { name: "angle-double-down", unicode: "f103" },
    { name: "angle-double-left", unicode: "f100" },
    { name: "angle-double-right", unicode: "f101" },
    { name: "angle-double-up", unicode: "f102" },
    { name: "angle-down", unicode: "f107" },
    { name: "angle-left", unicode: "f104" },
    { name: "angle-right", unicode: "f105" },
    { name: "angle-up", unicode: "f106" },
    { name: "angry", unicode: "f556" },
    { name: "ankh", unicode: "f644" },
    { name: "apple-alt", unicode: "f5d1" },
    { name: "archive", unicode: "f187" },
    { name: "archway", unicode: "f557" },
    { name: "arrow-alt-circle-down", unicode: "f358" },
    { name: "arrow-alt-circle-left", unicode: "f359" },
    { name: "arrow-alt-circle-right", unicode: "f35a" },
    { name: "arrow-alt-circle-up", unicode: "f35b" },
    { name: "arrow-circle-down", unicode: "f0ab" },
    { name: "arrow-circle-left", unicode: "f0a8" },
    { name: "arrow-circle-right", unicode: "f0a9" },
    { name: "arrow-circle-up", unicode: "f0aa" },
    { name: "arrow-down", unicode: "f063" },
    { name: "arrow-left", unicode: "f060" },
    { name: "arrow-right", unicode: "f061" },
    { name: "arrow-up", unicode: "f062" },
    { name: "arrows-alt", unicode: "f0b2" },
    { name: "arrows-alt-h", unicode: "f337" },
    { name: "arrows-alt-v", unicode: "f338" },
    { name: "assistive-listeningsystems", unicode: "f2a2" },
    { name: "asterisk", unicode: "f069" },
    { name: "at", unicode: "f1fa" },
    { name: "atlas", unicode: "f558" },
    { name: "atom", unicode: "f5d2" },
    { name: "audio-description", unicode: "f29e" },
    { name: "award", unicode: "f559" },
    { name: "backspace", unicode: "f55a" },
    { name: "backward", unicode: "f04a" },
    { name: "balance-scale", unicode: "f24e" },
    { name: "ban", unicode: "f05e" },
    { name: "band-aid", unicode: "f462" },
    { name: "barcode", unicode: "f02a" },
    { name: "bars", unicode: "f0c9" },
    { name: "baseball-ball", unicode: "f433" },
    { name: "basketball-ball", unicode: "f434" },
    { name: "bath", unicode: "f2cd" },
    { name: "battery-empty", unicode: "f244" },
    { name: "battery-full", unicode: "f240" },
    { name: "battery-half", unicode: "f242" },
    { name: "battery-quarter", unicode: "f243" },
    { name: "battery-three-quarters", unicode: "f241" },
    { name: "bed", unicode: "f236" },
    { name: "beer", unicode: "f0fc" },
    { name: "bell", unicode: "f0f3" },
    { name: "bell-slash", unicode: "f1f6" },
    { name: "bezier-curve", unicode: "f55b" },
    { name: "bible", unicode: "f647" },
    { name: "bicycle", unicode: "f206" },
    { name: "binoculars", unicode: "f1e5" },
    { name: "birthday-cake", unicode: "f1fd" },
    { name: "blender", unicode: "f517" },
    { name: "blind", unicode: "f29d" },
    { name: "bold", unicode: "f032" },
    { name: "bolt", unicode: "f0e7" },
    { name: "bomb", unicode: "f1e2" },
    { name: "bone", unicode: "f5d7" },
    { name: "bong", unicode: "f55c" },
    { name: "book", unicode: "f02d" },
    { name: "book-open", unicode: "f518" },
    { name: "book-reader", unicode: "f5da" },
    { name: "bookmark", unicode: "f02e" },
    { name: "bowling-ball", unicode: "f436" },
    { name: "box", unicode: "f466" },
    { name: "box-open", unicode: "f49e" },
    { name: "boxes", unicode: "f468" },
    { name: "braille", unicode: "f2a1" },
    { name: "brain", unicode: "f5dc" },
    { name: "briefcase", unicode: "f0b1" },
    { name: "briefcase-medical", unicode: "f469" },
    { name: "broadcast-tower", unicode: "f519" },
    { name: "broom", unicode: "f51a" },
    { name: "brush", unicode: "f55d" },
    { name: "bug", unicode: "f188" },
    { name: "building", unicode: "f1ad" },
    { name: "bullhorn", unicode: "f0a1" },
    { name: "bullseye", unicode: "f140" },
    { name: "burn", unicode: "f46a" },
    { name: "bus", unicode: "f207" },
    { name: "bus-alt", unicode: "f55e" },
    { name: "business-time", unicode: "f64a" },
    { name: "calculator", unicode: "f1ec" },
    { name: "calendar", unicode: "f133" },
    { name: "calendar-alt", unicode: "f073" },
    { name: "calendar-check", unicode: "f274" },
    { name: "calendar-minus", unicode: "f272" },
    { name: "calendar-plus", unicode: "f271" },
    { name: "calendar-times", unicode: "f273" },
    { name: "camera", unicode: "f030" },
    { name: "camera-retro", unicode: "f083" },
    { name: "cannabis", unicode: "f55f" },
    { name: "capsules", unicode: "f46b" },
    { name: "car", unicode: "f1b9" },
    { name: "car-alt", unicode: "f5de" },
    { name: "car-battery", unicode: "f5df" },
  ];
  const filteredIcons = icons.filter((icon) =>
    icon.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const getData = async () => {
    const { response } = await AppService.menu_edits.get({
      query: `companyId=${sso.companyId}`,
    });
    if (response) {
      const editmenus = response.data.filter(function (item) {
        return item.type == "edit";
      });
      const hidemenus = response.data.filter(function (item) {
        return item.type == "hide";
      });
      setEditedLabels(editmenus);
      setHiddenItems(hidemenus);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };
  const onLoad = async () => {
    // getData();
    console.log(data, "inernalinernalinernal");
    if (data?.edit_menus) {
      setEditedLabels(data?.edit_menus);
    }
    if (data?.hidemenus) {
      setHiddenItems(data?.hidemenus);
    }
  };
  useEffect(() => {
    onLoad();
  }, [data]);
  const openModal = () => {
    setShowModal(true);
    setEditMode(false);
  };
  const closeModal = () => setShowModal(false);

  const handleSelectChange = (event) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    setSelectedItem(event.target.value);
    setLabel(selectedOption.text.split(" ")[1]);
    setEditMode(false);
  };

  const handleLabelChange = (event) => {
    setLabel(event.target.value);
  };

  const addEdit = async () => {
    const originalItem = menuItems.find((item) => item.value === selectedItem);
    const originalLabel = originalItem ? originalItem.label : selectedItem;
    const labelExists = editedLabels.some(
      (edit) =>
        edit.originalLabel === originalLabel &&
        edit.newLabel.trim() === label.trim()
    );
    const isNewLabelEmpty = !label.trim();
    const iconValue = icon
      ? selectedIcon.unicode !== undefined
        ? `&#x${selectedIcon.unicode};`
        : ""
      : "";
    if (!labelExists && !isNewLabelEmpty) {
      setEditedLabels((prevLabels) => [
        ...prevLabels,
        {
          ...originalItem,
          originalLabel: originalLabel,
          repositionMenu: repositionMenu,
          menuPlacement: menuPlacement,
          menuTarget: menuTarget,
          icon: iconValue,
          // icon: `&#x${selectedIcon.unicode};`,
          newLabel: label.trim(),
          companyId: sso.companyId,
          type: "edit",
        },
      ]);

      const edit_menus = {
        ...originalItem,
        originalLabel: originalLabel,
        repositionMenu: repositionMenu,
        menuPlacement: menuPlacement,
        menuTarget: menuTarget,
        icon: `&#x${selectedIcon.unicode};`,
        newLabel: label.trim(),
        companyId: sso.companyId,
        type: "edit",
      };
      const payload = {
        companyId: sso.companyId,
        edit_menus: [...editedLabels, edit_menus],
      };

      if (type == "all_location") {
        if (data?._id) {
          payload._id = data?._id;
        }
        var { response } = await AppService.sidenav_customizer_main_level[
          data?._id ? "update" : "create"
        ]({
          payload: payload,
        });
      } else {
        if (data?._id) {
          payload._id = data?._id;
        }
        var { response } =
          await AppService.sidenav_customizer_custom_level.update({
            payload: payload,
          });
      }
      setLabel("");
      setEditMode(false);
      setSelectedItem("");
      setSelectedIcon(false);
    }
    console.log(editedLabels);
  };

  const handleHide = () => {
    if (selectedItem) {
      setHideMode(true);
    } else {
      alert("Please select an item to edit.");
    }
  };
  const handleHideClick = async () => {
    
    if (
      selectedItem &&
      hiddenItems.filter((item) => item.originalLabel == selectedItem)
        ?.length == 0
    ) {
      console.log(selectedItem);
      const originalItem = menuItems.find(
        (item) => item.value === selectedItem
      );
      const edit_menus = {
        ...originalItem,
        originalLabel: selectedItem,
        companyId: sso.companyId,
        type: "hide",
        // permsiion: "hide",
        permissions: hidePermissionData,
      };
      const payload = {
        companyId: sso.companyId,
        type: "location",
        hidemenus: [...hiddenItems, edit_menus],
      };
      if (type == "all_location") {
        console.log(data, "data");
        if (data?._id) {
          payload._id = data?._id;
        }
        var { response } = await AppService.sidenav_customizer_main_level[
          data?._id ? "update" : "create"
        ]({
          payload: payload,
        });
      } else {
        if (data?._id) {
          payload._id = data?._id;
        }
        var { response } =
          await AppService.sidenav_customizer_custom_level.update({
            payload: payload,
          });
      }
      if (response) {
        setHiddenItems([...hiddenItems, edit_menus]);
        setHideMode(false)
      }
      setSelectedItem("");
    } else {
      if (!selectedItem) {
        alert("Please select an item to hide.");
      } else {
        alert(`${selectedItem} is already exist`);
        setHideMode(false);
      }
    }

    //
  };
  const handleEditClick = () => {
    if (selectedItem) {
      setEditMode(true);
    } else {
      alert("Please select an item to edit.");
    }
  };

  async function removeHiddenItem(indexToRemove) {
    console.log(indexToRemove);
    const filtered_data = hiddenItems;
    filtered_data.splice(indexToRemove, 1);
    console.log(filtered_data, "filtered_datafiltered_data");
    const payload = {
      hidemenus: filtered_data,
    };
    if (type == "all_location") {
      console.log(data, "data");
      if (data?._id) {
        payload._id = data?._id;
      }
      var { response } = await AppService.sidenav_customizer_main_level.update({
        payload: payload,
      });
    } else {
      if (data?._id) {
        payload._id = data?._id;
      }
      var { response } =
        await AppService.sidenav_customizer_custom_level.update({
          payload: payload,
        });
    }
    setHiddenItems(filtered_data);
    setrefresh(!refresh);
  }

  const handleDeleteEdit = async (indexToRemove) => {
    console.log(indexToRemove);
    const filtered_data = editedLabels;
    filtered_data.splice(indexToRemove, 1);
    console.log(filtered_data, "filtered_datafiltered_data");
    const payload = {
      edit_menus: filtered_data,
    };
    if (type == "all_location") {
      console.log(data, "data");
      if (data?._id) {
        payload._id = data?._id;
      }
      var { response } = await AppService.sidenav_customizer_main_level.update({
        payload: payload,
      });
    } else {
      if (data?._id) {
        payload._id = data?._id;
      }
      var { response } =
        await AppService.sidenav_customizer_custom_level.update({
          payload: payload,
        });
    }
    setEditedLabels(filtered_data);
    setrefresh(!refresh);
  };
  const menuItems = [
    {
      label: "Launch Pad",
      value: "launchpad_v2",
      selector: "#sb_launchpad",
      level: "sidebar",
      icon: "⚡",
    },
    {
      label: "Dashboard",
      value: "dashboard_v2",
      selector: "#sb_dashboard",
      level: "sidebar",
      icon: "⚡",
    },
    {
      label: "Conversations",
      value: "conversations_v2",
      level: "sidebar",
      selector: "#sb_conversations",
      icon: "📁",
    },

    {
      label: "Conversations",
      value: "tb_conversations_v2",
      level: "nested",
      path: "conversations",
      selector: "#tb_conversations-tab",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Manual Actions",
      value: "manual_actions_v2",
      level: "nested",
      path: "conversations",
      selector: "#tb_manial-actions",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Templates",
      value: "conversations_templates_v2",
      level: "nested",
      path: "conversations",
      selector: "#tb_conversations-templates",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Trigger Links",
      value: "trigger_links_v2",
      level: "nested",
      path: "conversations",
      selector: "#tb_trigger-links",
      icon: "➜",
      type: "subMenu",
    },

    {
      label: "Calendars",
      value: "calendars_v2",
      level: "sidebar",
      selector: "#sb_calendars",
      icon: "📁",
    },
    {
      label: "Calendars",
      value: "calendars_sub_v2",
      level: "nested",
      path: "calendars",
      selector: "#tb_calendars-tab",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Appointments",
      value: "appointments_v2",
      level: "nested",
      path: "calendars",
      selector: "#tb_apppontment-tab",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Calendar Settings",
      value: "calendar_settings_v2",
      level: "calendars",
      path: "calendars",
      selector: "#tb_calendar-settings-top",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Contacts",
      value: "contacts_v2",
      level: "sidebar",
      selector: "#sb_contacts",
      icon: "📁",
    },
    {
      label: "Contacts",
      value: "contacts_v2",
      level: "nested",
      path: "contacts",
      selector: "sb_launchpad",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Smart Lists",
      value: "smart_list_v2",
      level: "nested",
      path: "contacts",
      selector: "#tb_lists",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Contact Requests",
      value: "contact_requests_v2",
      level: "nested",
      path: "kjhjkh",
      selector: "#tb_contact-requests",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Bulk Actions",
      value: "bulk_actions_v2",
      level: "nested",
      path: "contacts",
      selector: "#tb_bulk-actions",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Restore",
      value: "restore_v2",
      level: "nested",
      path: "contacts",
      selector: "#tb_contacts-restore",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Tasks",
      value: "tb_tasks",
      level: "nested",
      path: "contacts",
      selector: "#tb_tasks",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Company",
      value: "tb_business",
      level: "nested",
      path: "contacts",
      selector: "#tb_business",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Manage Smart Lists",
      value: "manage_smart_lists_v2",
      level: "nested",
      path: "contacts",
      selector: "#tb_contacts-settings-top",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Settings Gear: Custom Field Settings",
      value: "tb_contact-custom-fields-settings",
      level: "nested",
      path: "contacts",
      selector: "#tb_contact-custom-fields-settings",
      icon: "➜",
      type: "subMenu",
    },

    {
      label: "Opportunities",
      value: "opportunities_v2",
      level: "sidebar",
      selector: "#sb_opportunities",
      icon: "📁",
    },

    {
      label: "Opportunities",
      value: "opportunities_sub_v2",
      level: "nested",
      path: "opportunities",
      selector: "#tb_opportunities-tab",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Pipelines",
      value: "pipelines_v2",
      level: "nested",
      path: "opportunities",
      selector: "#tb_pipeline",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Payments",
      value: "payments_v2",
      level: "sidebar",
      selector: "#sb_payments",
      icon: "📁",
    },
    {
      label: "Invoices",
      value: "tb_payment-invoices",
      level: "nested",
      path: "payments",
      selector: "#tb_payment-invoices",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Recurring Templates",
      value: "tb-sub_payment-invoices",
      level: "nested",
      path: "payments",
      selector: "#tb-sub_payment-invoices",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Documents & Contracts",
      value: "tb_proposals-estimates",
      level: "nested",
      path: "payments",
      selector: "#tb_proposals-estimates",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Orders",
      value: "tb_payment-orders-new",
      level: "nested",
      path: "payments",
      selector: "#tb_payment-orders-new",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Abandoned Checkouts",
      value: "tb-sub_all-abandoned-checkouts",
      level: "nested",
      path: "payments",
      selector: "#tb-sub_all-abandoned-checkouts",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Subscriptions",
      value: "tb_payment-subscriptions",
      level: "nested",
      path: "payments",
      selector: "#tb_payment-subscriptions",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Payment Links",
      value: "tb_payment-links",
      level: "nested",
      path: "payments",
      selector: "#tb_payment-links",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Transactions",
      value: "tb_payment-transactions-new",
      level: "nested",
      path: "payments",
      selector: "#tb_payment-transactions-new",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Products",
      value: "tb_payments-products",
      level: "nested",
      path: "payments",
      selector: "#tb_payments-products",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Coupons",
      value: "tb_payments-coupons",
      level: "nested",
      path: "payments",
      selector: "#tb_payments-coupons",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Settings",
      value: "tb_payment-settings",
      level: "nested",
      path: "payments",
      selector: "#tb_payment-settings",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Integrations",
      value: "tb_payment-integrations",
      level: "nested",
      path: "payments",
      selector: "#tb_payment-integrations",
      icon: "➜",
      type: "subMenu",
    },

    {
      label: "Marketing",
      value: "marketing",
      level: "sidebar",
      selector: "#sb_email-marketing",
      icon: "📁",
    },
    {
      label: "Social Planner",
      value: "social_planner_v2",
      level: "nested",
      path: "marketing",
      selector: "#tb_social-planner",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Countdown Timers",
      value: "countdown_timers_v2",
      level: "nested",
      path: "marketing",
      selector: "#tb_countdown-timer",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Brand Boards",
      value: "brand_boards_v2",
      level: "nested",
      path: "marketing",
      selector: "#tb_brand-boards",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Email Builder",
      value: "email_builder_v2",
      level: "nested",
      path: "marketing",
      selector: "#tb_email-builder",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Templates",
      value: "templates_v2",
      level: "nested",
      path: "marketing",
      selector: "#tb_email-templates",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Trigger Links",
      value: "marketing_trigger_links_v2",
      level: "nested",
      path: "marketing",
      selector: "#tb_trigger-links",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "HTML Builder",
      value: "html_builder-v2",
      level: "nested",
      path: "kjhjkh",
      selector: "#tb_html-builder",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Affiliate Manager",
      value: "tb_affiliate-manager",
      level: "nested",
      path: "marketing",
      selector: "#tb_affiliate-manager",
      icon: "➜",
      type: "subMenu",
    },

    {
      label: "Automation",
      value: "automation",
      level: "sidebar",
      selector: "#sb_automation",
      icon: "📁",
    },
    {
      label: "Workflows",
      value: "workflows_v2",
      level: "nested",
      path: "automation",
      selector: "#tb_workflow",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Content AI",
      value: "contentai",
      level: "automation",
      path: "kjhjkh",
      selector: "#tb_content-ai",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Global Workflow Settings",
      value: "global_workflow_setings_v2",
      level: "nested",
      path: "automation",
      selector: "#tb_workflow-settings-top",
      icon: "➜",
      type: "subMenu",
    },
    // {
    // label: "Campaigns",
    // value: "campaigns_v2",
    // level: "nested",
    // path:"kjhjkh",
    // selector: "sb_launchpad",
    // icon: "➜",
    // type: "subMenu",
    // },
    // {
    // label: "Triggers",
    // value: "triggers_v2",
    // level: "nested",
    // path:"kjhjkh",
    // selector: "sb_launchpad",
    // icon: "➜",
    // type: "subMenu",
    // },
    {
      label: "Sites",
      value: "sites-v2",
      level: "sidebar",
      selector: "#sb_sites",
      icon: "📁",
    },
    {
      label: "Funnels",
      value: "funnels_v2",
      level: "nested",
      path: "funnels-websites",
      selector: "#tb_funnels",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Websites",
      value: "websites_v2",
      level: "nested",
      path: "funnels-websites",
      selector: "#tb_websites",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Blogs",
      value: "blogs",
      level: "nested",
      path: "funnels-websites",
      selector: "#tb_blogs",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Wordpress",
      value: "wordpress_v2",
      level: "nested",
      path: "funnels-websites",
      selector: "#tb_wordpress",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Client Portal",
      value: "clientportal",
      level: "nested",
      path: "funnels-websites",
      selector: "#tb_clientportal",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Forms",
      value: "forms_v2",
      level: "nested",
      path: "funnels-websites",
      selector: "#tb_form-builder",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Surveys",
      value: "surveys_v2",
      level: "nested",
      path: "funnels-websites",
      selector: "#tb_survey-builder",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Chat Widget",
      value: "chat_widget_v2",
      level: "nested",
      path: "funnels-websites",
      selector: "#tb_chat-widget",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Media",
      value: "tb_media",
      level: "nested",
      path: "funnels-websites",
      selector: "#tb_media",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "URL Redirects",
      value: "url_redirects_v2_tb",
      level: "nested",
      path: "funnels-websites",
      selector: "#tb_url-redirects",
      icon: "➜",
      type: "subMenu",
    },

    {
      label: "Memberships",
      value: "memberships_v2",
      level: "sidebar",
      selector: "#sb_memberships",
      icon: "📁",
    },
    {
      label: "Courses",
      value: "tb_courses",
      level: "nested",
      path: "memberships",
      selector: "#tb_courses",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Dashboard",
      value: "sub_membership-dashboard",
      level: "nested",
      path: "memberships",
      selector: "#tb-sub_dashboard",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Products",
      value: "sub_membership-products",
      level: "nested",
      path: "memberships",
      selector: "#tb-sub_products",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Offers",
      value: "sub_membership-offers",
      level: "nested",
      path: "memberships",
      selector: "#tb-sub_offers",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Analytics",
      value: "sub_membership-analytics",
      level: "nested",
      path: "memberships",
      selector: "#tb-sub_analytics",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Settings",
      value: "sub_membership-settings",
      level: "nested",
      path: "memberships",
      selector: "#tb-sub_settings",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "communities",
      value: "tb_communities-settings",
      level: "nested",
      path: "memberships",
      selector: "#tb_communities",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Certificates",
      value: "tb_certificates-settings",
      level: "nested",
      path: "memberships",
      selector: "#tb_certificates",
      icon: "➜",
      type: "subMenu",
    },

    {
      label: "Reputation",
      value: "reputation_v2",
      level: "sidebar",
      selector: "#sb_reputation",
      icon: "📁",
    },
    {
      label: "Overview",
      value: "overview_v2",
      level: "nested",
      path: "reputation",
      selector: "#tb_overview",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Requests",
      value: "requests_v2",
      level: "nested",
      path: "reputation",
      selector: "#tb_reputations-requests",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Reviews",
      value: "reviews_v2",
      level: "nested",
      path: "reputation",
      selector: "#tb_reputations-reviews",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Widget",
      value: "tb_widget",
      level: "nested",
      path: "reputation",
      selector: "#tb_widget",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Listings",
      value: "listings_v2",
      level: "nested",
      path: "reputation",
      selector: "#tb_online-listings",
      icon: "➜",
      type: "subMenu",
    },

    {
      label: "Reporting",
      value: "reporting_v2",
      level: "sidebar",
      selector: "#sb_reporting",
      icon: "📁",
    },
    {
      label: "Google Ads",
      value: "google_ads_v2",
      level: "nested",
      path: "reporting",
      selector: "#tb_google-ads",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Facebook Ads",
      value: "facebook_ads_v2",
      level: "nested",
      path: "reporting",
      selector: "#tb_facebook-ads",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Attribution Report",
      value: "attribution_report_v2",
      level: "nested",
      path: "reporting",
      selector: "#tb_attribution-report",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Call reporting",
      value: "call_reporting_v2",
      level: "nested",
      path: "reporting",
      selector: "#tb_call-reporting",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Agent Reporting",
      value: "agent_reporting_v2",
      level: "nested",
      path: "reporting",
      selector: "#tb_agent-reporting",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Appointment Report",
      value: "appointment_report_v2",
      level: "nested",
      path: "reporting",
      selector: "#tb_appointment-report",
      icon: "➜",
      type: "subMenu",
    },

    {
      label: "App Marketplace",
      value: "sb_app-marketplace",
      level: "sidebar",
      selector: "#sb_app-marketplace",
      icon: "📁",
    },
    {
      label: "Mobile App",
      value: "mobileapp",
      level: "sidebar",
      selector: "#sb_location-mobile-app",
      icon: "📁",
    },
    {
      label: "Settings",
      value: "settings_v2",
      level: "sidebar",
      selector: "#sb_settings",
      icon: "📁",
    },
    {
      label: "Business Info",
      value: "business_info_v2",
      level: "nested",
      path: "settings",
      selector: "sb_launchpad",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "My Staff",
      value: "my_staff_v2",
      level: "nested",
      path: "settings",
      selector: "sb_launchpad",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Pipeline",
      value: "pipeline_v2",
      level: "nested",
      path: "settings",
      selector: "sb_launchpad",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Calendars",
      value: "calendars_settings_v2",
      level: "nested",
      path: "",
      selector: "sb_launchpad",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Conversation AI",
      value: "conversation_ai_settings",
      level: "nested",
      path: "",
      selector: "sb_launchpad",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Phone Numbers",
      value: "phone_numbers_v2",
      level: "nested",
      path: "",
      selector: "sb_launchpad",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Whats App",
      value: "sb_whatsapp",
      level: "nested",
      path: "",
      selector: "sb_launchpad",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Reputation Management",
      value: "reputation_management_v2",
      level: "nested",
      path: "",
      selector: "sb_launchpad",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Custom Fields",
      value: "custom_fields_v2",
      level: "nested",
      path: "",
      selector: "sb_launchpad",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Custom Values",
      value: "custom_values_v2",
      level: "nested",
      path: "",
      selector: "sb_launchpad",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Manage Scoring",
      value: "sb_manage-scoring",
      level: "nested",
      path: "",
      selector: "sb_launchpad",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Domains",
      value: "domains_v2",
      level: "nested",
      path: "",
      selector: "sb_launchpad",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Media",
      value: "media_v2",
      level: "nested",
      path: "",
      selector: "sb_launchpad",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "URL Redirects",
      value: "url_redirects_v2",
      level: "nested",
      path: "",
      selector: "sb_launchpad",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Integrations",
      value: "integrations_v2",
      level: "nested",
      path: "",
      selector: "sb_launchpad",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Email Services",
      value: "email_services_v2",
      level: "nested",
      path: "",
      selector: "sb_launchpad",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Conversation Providers",
      value: "conversations_providers_v2",
      level: "nested",
      path: "",
      selector: "sb_launchpad",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "SMTP & Mailgun Services",
      value: "smtp_services_v2",
      level: "nested",
      path: "",
      selector: "sb_launchpad",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Tags",
      value: "tags_v2",
      level: "nested",
      path: "",
      selector: "sb_launchpad",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Labs",
      value: "labs_v2",
      level: "nested",
      path: "",
      selector: "sb_launchpad",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Audit Logs",
      value: "auditlogs_v2",
      level: "nested",
      path: "",
      selector: "sb_launchpad",
      icon: "➜",
      type: "subMenu",
    },
    {
      label: "Companies",
      value: "companies_v2",
      level: "nested",
      path: "",
      selector: "sb_launchpad",
      icon: "➜",
      type: "subMenu",
    },
  ];

  return (
    <>
      <Button variant="primary" onClick={openModal} className="w-100">
        Menu Edits
      </Button>

      <Modal show={showModal} onHide={closeModal} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Menu Editor</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "50vh", overflowY: "auto" }}>
          <div className="d-flex align-items-center gap-2">
            <select
              value={selectedItem}
              onChange={handleSelectChange}
              className="form-control"
            >
              <option value="">Select an item</option>
              {menuItems
                .filter(function (item) {
                  return !editedLabels?.some(function (editedLabel) {
                    return editedLabel.selector === item.selector;
                  });
                })
                .filter(function (item) {
                  return !hiddenItems?.some(function (hiddenItem) {
                    return hiddenItem.selector === item.selector;
                  });
                })
                .map(function (filteredItem, index) {
                  return (
                    <option
                      key={index}
                      value={filteredItem.value}
                      style={
                        filteredItem.type === "subMenu"
                          ? { paddingLeft: "10px" }
                          : {}
                      }
                    >
                      {filteredItem?.icon && <span>{filteredItem.icon}</span>}{" "}
                      {filteredItem.label}
                    </option>
                  );
                })}
            </select>
            <Button onClick={handleHide}>
              {/* <Button onClick={handleHideClick}> */}
              <IoMdEyeOff style={{ fontSize: "16px", color: "white" }} />
            </Button>
            <Button onClick={handleEditClick}>
              <FaEdit style={{ fontSize: "16px", color: "white" }} />
            </Button>
          </div>

          {editMode && (
            <Form>
              <Form.Group className="mb-2" controlId="formItemLabel">
                <Form.Label>Menu Item Label</Form.Label>
                <Form.Control
                  type="text"
                  value={label}
                  onChange={handleLabelChange}
                />
              </Form.Group>
              <Form.Group className="mb-2" controlId="formIcons">
                <Form.Label>Icons</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Search Icon"
                  name="icon"
                  defaultValue={showIconSelector ? "" : selectedIcon?.name}
                  onClick={() => setShowIconSelector(!showIconSelector)}
                  onChange={handleIconInputChange}
                />
                {showIconSelector && (
                  <div>
                    <div
                      style={{
                        maxHeight: "150px",
                        overflowY: "auto",
                        border: "1px solid #ccc",
                        padding: "5px",
                      }}
                    >
                      {filteredIcons.map((icon, index) => (
                        <span
                          key={index}
                          onClick={() => handleIconClick(icon)}
                          style={{ cursor: "pointer", marginRight: "10px" }}
                        >
                          <i
                            className={`fas fa-${icon.name}`}
                            style={{ fontSize: "24px" }}
                          />
                        </span>
                      ))}
                    </div>
                  </div>
                )}
                {/* <Form.Check
                                type="checkbox"
                                label="Use a custom image icon"
                                name="useCustomImage"
                                checked={formData.useCustomImage}
                                onChange={handleInputChange}
                            />
                            {formData.useCustomImage && (
                                <Form.Control
                                    type="file"
                                    name="customImage"
                                    onChange={handleInputChange}
                                />
                            )} */}
              </Form.Group>
              <Form.Group className="mb-2" controlId="formItemposition">
                <div class="form-check form-switch mb-2">
                  <input
                    class="form-check-input"
                    onChange={(e) => setRepositionMenu(e.target.checked)}
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                  <label class="form-check-label" for="flexSwitchCheckDefault">
                    Re position Menu
                  </label>
                </div>
              </Form.Group>
              {repositionMenu && (
                <>
                  <Form.Group className="mb-2" controlId="placement">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        onClick={() => setMenuPlacement("before")}
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                      />
                      <label class="form-check-label" for="flexRadioDefault1">
                        Before
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        onClick={() => setMenuPlacement("after")}
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                      />
                      <label class="form-check-label" for="flexRadioDefault1">
                        After
                      </label>
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="target">
                    <select
                      className="form-control"
                      name="target"
                      onChange={(e) =>
                        setMenuTarget(JSON.parse(e.target.value))
                      }
                    >
                      <option value="">Select an item</option>
                      {menuItems
                        .filter((item) => item.level == "sidebar")
                        .map(function (filteredItem, index) {
                          return (
                            <option
                              key={index}
                              value={JSON.stringify(filteredItem)}
                            >
                              {filteredItem?.icon && (
                                <span>{filteredItem.icon}</span>
                              )}{" "}
                              {filteredItem.label}
                            </option>
                          );
                        })}
                    </select>
                  </Form.Group>
                </>
              )}
              <Button variant="secondary" onClick={() => setEditMode(false)}>
                Cancel
              </Button>
              <Button variant="primary" onClick={addEdit} className="ms-2">
                Add Menu Edit
              </Button>
            </Form>
          )}

          {hideMode && (
            <Form>
              <Form.Group className="mb-3" controlId="formLink">
                <Form.Label>Hide Menu For User</Form.Label>
                <Form.Check
                  checked={hidePermissionData.user_permissions.agency_admins}
                  type="checkbox"
                  label="Agency Admins"
                  name="agency_admins"
                  onChange={handlePermissionsChange}
                />
                <Form.Check
                  checked={hidePermissionData.user_permissions.agency_users}
                  type="checkbox"
                  label="Agency Users"
                  name="agency_users"
                  onChange={handlePermissionsChange}
                />
                <Form.Check
                  checked={hidePermissionData.user_permissions.account_admins}
                  type="checkbox"
                  label="Account Admins"
                  name="account_admins"
                  onChange={handlePermissionsChange}
                />
                <Form.Check
                  checked={hidePermissionData.user_permissions.account_users}
                  type="checkbox"
                  label="Account Users"
                  name="account_users"
                  onChange={handlePermissionsChange}
                />
              </Form.Group>

              <Button variant="secondary" onClick={() => setHideMode(false)}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleHideClick}
                className="ms-2"
              >
                Add Hide Item
              </Button>
            </Form>
          )}

          <div>
            {editedLabels?.length > 0 && (
              <div>
                <p
                  style={{
                    background: "rgb(194, 194, 194)",
                    width: "6rem",
                    color: "rgb(79, 79, 79)",
                    textAlign: "center",
                    fontSize: "8pt",
                    borderRadius: "3px",
                    marginTop: "10px",
                  }}
                >
                  Edited Menus
                </p>
                {editedLabels.map((edit, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-between align-items-center p-2 mb-1"
                    style={{
                      borderLeft: "2px solid rgb(63, 63, 63)",
                      background: "rgb(229, 229, 232)",
                    }}
                  >
                    <div className="d-flex align-items-center gap-2">
                      <span style={{ color: "black" }}>
                        {edit.originalLabel}
                      </span>{" "}
                      <span style={{ fontSize: "14px", color: "black" }}>
                        &#10148;
                      </span>{" "}
                      <span style={{ color: "black" }}>{edit.newLabel}</span>
                    </div>
                    {/* <span>{edit._id}</span> */}
                    <BsTrash
                      onClick={() => handleDeleteEdit(index)}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>

          <div>
            {hiddenItems?.length > 0 && (
              <div style={{ marginTop: "20px" }}>
                <p
                  style={{
                    background: "rgb(194, 194, 194)",
                    width: "6rem",
                    color: "rgb(79, 79, 79)",
                    textAlign: "center",
                    fontSize: "8pt",
                    borderRadius: "3px",
                    marginTop: "10px",
                  }}
                >
                  Hidden Menus
                </p>
                {hiddenItems.map((itemValue, index) => {
                  const item = menuItems.find(
                    (menuItem) => menuItem.value === itemValue
                  );
                  return (
                    <div
                      key={index}
                      className="d-flex justify-content-between align-items-center p-2 mb-1 "
                      style={{
                        borderLeft: "2px solid rgb(63, 63, 63)",
                        background: "rgb(229, 229, 232)",
                      }}
                    >
                      <div style={{ color: "black" }}>
                        {itemValue?.icon && <span>{itemValue.icon}</span>}{" "}
                        {itemValue?.originalLabel}
                        {/* {JSON.stringify(itemValue)} */}
                      </div>

                      <BsTrash
                        style={{ cursor: "pointer" }}
                        onClick={() => removeHiddenItem(index)}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={closeModal}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MenuEditorModal;
