import axios from "axios";
import { ReqMethods, ResponseStatus } from "../enums";
import env from "../config";
// import { setNotification, useNotifcation } from "context/notification";
import { useAuth, useLogout } from "../hook/auth";

const BASE_URL = `${env.API_URL}`;

function useServiceHandler() {
  // const [, dispatch] = useNotifcation();
  const Logout = useLogout();

  return (fn) =>
    async (
      method,
      path,
      { query, payload, token, apiKey, toaster, message, error } = {}
    ) => {
      try {
        const res = await fn(method, path, { query, payload, token, apiKey });
        // console.log("API - RESPONSE", res, toaster, message, error);
        // toaster &&
        //   setNotification(dispatch, {
        //     open: true,
        //     message: message || res.data.message,
        //     title: "Success",
        //   });

        return { response: res.data };
      } catch (err) {
        console.log("API- ERROR", err.response?.data || err);

        // expire error : jwt expired
        if (
          err.response &&
          err.response.status === ResponseStatus.UNAUTHORIZED
        ) {
          // setNotification(dispatch, {
          //   open: true,
          //   message: error || "session expired",
          //   title: "Error",
          //   severity: "error",
          // });
          setTimeout(Logout, 4000);
          return { error: err.response?.data || err };
        }

        // const customError = err.response?.data?.error
        //   ? `${err.response?.data?.message} \n${err.response?.data?.error}`
        //   : err.response?.data?.message;

        // toaster &&
        //   setNotification(dispatch, {
        //     open: true,
        //     message: error || customError || err.message,
        //     severity: "error",
        //     title: "Error",
        //   });
        return { error: err.response ? err.response.data : err };
      }
    };
}

function useCallService() {
  const authToken = useAuth();
  const serviceHandler = useServiceHandler();

  const CallService = (
    method,
    path,
    { query, payload, token = true, apiKey = null }
  ) => {
    const pathname = query ? `${path}?${query}` : path;
    const config = {};

    if (token)
      config.headers = { "x-auth-token": `Bearer ${authToken || token}` };
    if (apiKey) config.headers = { apiKey };

    const details = {};

    if (payload) details.payload = payload;
    details.config = config;

    return axios[method](pathname, ...Object.values(details));
  };

  return serviceHandler(CallService);
}
function useAppServices() {
  const { GET, POST, PUT, DELETE } = ReqMethods;
  const CallService = useCallService();

  /**
    Option for service is the object that could has the following properties
    query, payload, token, apiKey
  */

  const APIs = {
    auth: {
      login: (options) => CallService(POST, `${BASE_URL}/auth/login`, options),
      signup: (options) =>
        CallService(POST, `${BASE_URL}/auth/signup`, options),
    },
    top_navigation: {
      get: (options) => CallService(GET, `${BASE_URL}/top_navigation`, options),
      create: (options) =>
        CallService(POST, `${BASE_URL}/top_navigation`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/top_navigation/filter`, options),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/top_navigation`, options),
      change_sorting: (options) =>
        CallService(POST, `${BASE_URL}/top_navigation/change_sorting`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/top_navigation`, options),
    },
    menu_edits: {
      get: (options) => CallService(GET, `${BASE_URL}/menu_edits`, options),
      create: (options) => CallService(POST, `${BASE_URL}/menu_edits`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/menu_edits/filter`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/menu_edits`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/menu_edits`, options),
    },
    header_buttons: {
      get: (options) => CallService(GET, `${BASE_URL}/header_buttons`, options),
      create: (options) =>
        CallService(POST, `${BASE_URL}/header_buttons`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/header_buttons/filter`, options),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/header_buttons`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/header_buttons`, options),
    },
    launchPad: {
      get: (options) => CallService(GET, `${BASE_URL}/launchPad`, options),
      create: (options) => CallService(POST, `${BASE_URL}/launchPad`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/launchPad/filter`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/launchPad`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/launchPad`, options),
    },
    userSettings: {
      get: (options) => CallService(GET, `${BASE_URL}/userSettings`, options),
      create: (options) =>
        CallService(POST, `${BASE_URL}/userSettings`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/userSettings/filter`, options),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/userSettings`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/userSettings`, options),
    },
    opportunities: {
      get: (options) => CallService(GET, `${BASE_URL}/opportunities`, options),
      create: (options) =>
        CallService(POST, `${BASE_URL}/opportunities`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/opportunities/filter`, options),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/opportunities`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/opportunities`, options),
    },
    setting_menu: {
      get: (options) => CallService(GET, `${BASE_URL}/setting_menu`, options),
      create: (options) =>
        CallService(POST, `${BASE_URL}/setting_menu`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/setting_menu/filter`, options),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/setting_menu`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/setting_menu`, options),
    },
    sidenav_customizer_main_level: {
      get: (options) =>
        CallService(GET, `${BASE_URL}/sidenav_customizer_main_level`, options),
      create: (options) =>
        CallService(POST, `${BASE_URL}/sidenav_customizer_main_level`, options),
      filter: (options) =>
        CallService(
          GET,
          `${BASE_URL}/sidenav_customizer_main_level/filter`,
          options
        ),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/sidenav_customizer_main_level`, options),
      delete: (options) =>
        CallService(
          DELETE,
          `${BASE_URL}/sidenav_customizer_main_level`,
          options
        ),
    },
    sidenav_customizer_custom_level: {
      get: (options) =>
        CallService(
          GET,
          `${BASE_URL}/sidenav_customizer_custom_level`,
          options
        ),
      create: (options) =>
        CallService(
          POST,
          `${BASE_URL}/sidenav_customizer_custom_level`,
          options
        ),
      filter: (options) =>
        CallService(
          GET,
          `${BASE_URL}/sidenav_customizer_custom_level/filter`,
          options
        ),
      update: (options) =>
        CallService(
          PUT,
          `${BASE_URL}/sidenav_customizer_custom_level`,
          options
        ),
      delete: (options) =>
        CallService(
          DELETE,
          `${BASE_URL}/sidenav_customizer_custom_level`,
          options
        ),
    },
    user: {
      get: (options) => CallService(GET, `${BASE_URL}/user`, options),
      filter: (options) => CallService(GET, `${BASE_URL}/user/filter`, options),
      register: (options) =>
        CallService(POST, `${BASE_URL}/user/register`, options),
      add_team: (options) =>
        CallService(POST, `${BASE_URL}/user/add_team `, options),
      location_user: (options) =>
        CallService(GET, `${BASE_URL}/user/location_user`, options),
      subscriber: (options) =>
        CallService(GET, `${BASE_URL}/user/subscriber`, options),
      agency_user: (options) =>
        CallService(GET, `${BASE_URL}/user/agency_user`, options),
    },
    brand: {
      get: (options) => CallService(GET, `${BASE_URL}/brand`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/brand`, options),
      create: (options) => CallService(POST, `${BASE_URL}/brand`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/brand/filter`, options),
    },
    script: {
      get: (options) => CallService(GET, `${BASE_URL}/script`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/script`, options),
      create: (options) => CallService(POST, `${BASE_URL}/script`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/script`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/script/filter`, options),
    },
    agency_app: {
      get: (options) => CallService(GET, `${BASE_URL}/agency_app`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/agency_app`, options),
      create: (options) => CallService(POST, `${BASE_URL}/agency_app`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/agency_app`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/agency_app/filter`, options),
    },
    app_token: {
      get: (options) => CallService(GET, `${BASE_URL}/app_token`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/app_token`, options),
      create: (options) => CallService(POST, `${BASE_URL}/app_token`, options),
      reconnect: (options) => CallService(POST, `${BASE_URL}/app_token/reconnect`, options),
      nested_app: (options) => CallService(POST, `${BASE_URL}/app_token/nested_app`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/app_token`, options),
      googleAccessToken: (options) =>
        CallService(GET, `${BASE_URL}/app_token/google/access-token`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/app_token/filter`, options),
    },
    contactButtonLink: {
      get: (options) =>
        CallService(GET, `${BASE_URL}/contactButtonLink`, options),
      create: (options) =>
        CallService(POST, `${BASE_URL}/contactButtonLink`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/contactButtonLink/filter`, options),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/contactButtonLink`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/contactButtonLink`, options),
    },
    contactButtons: {
      get: (options) => CallService(GET, `${BASE_URL}/contact_button`, options),
      create: (options) =>
        CallService(POST, `${BASE_URL}/contact_button`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/contact_button/filter`, options),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/contact_button`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/contact_button`, options),
    },
    contact_buttons_group: {
      get: (options) => CallService(GET, `${BASE_URL}/contact_buttons_group`, options),
      create: (options) =>
        CallService(POST, `${BASE_URL}/contact_buttons_group`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/contact_buttons_group/filter`, options),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/contact_buttons_group`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/contact_buttons_group`, options),
    },
    apps: {
      get: (options) => CallService(GET, `${BASE_URL}/apps`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/apps`, options),
      create: (options) => CallService(POST, `${BASE_URL}/apps`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/apps`, options),
      getTrackingId: (options) =>
        CallService(GET, `${BASE_URL}/apps/tracking-id`, options),
      filter: (options) => CallService(GET, `${BASE_URL}/apps/filter`, options),
    },
    category: {
      get: (options) => CallService(GET, `${BASE_URL}/category`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/category`, options),
      create: (options) => CallService(POST, `${BASE_URL}/category`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/category`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/category/filter`, options),
    },
    sub_category: {
      get: (options) => CallService(GET, `${BASE_URL}/sub_category`, options),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/sub_category`, options),
      create: (options) =>
        CallService(POST, `${BASE_URL}/sub_category`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/sub_category`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/sub_category/filter`, options),
    },
    widget: {
      get: (options) => CallService(GET, `${BASE_URL}/widget`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/widget`, options),
      create: (options) => CallService(POST, `${BASE_URL}/widget`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/widget`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/widget/filter`, options),
    },
    notes: {
      get: (options) => CallService(GET, `${BASE_URL}/notes`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/notes`, options),
      create: (options) => CallService(POST, `${BASE_URL}/notes`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/notes/filter`, options),
    },
    reports: {
      customer: (options) =>
        CallService(GET, `${BASE_URL}/reports/customer`, options),
      type_campaigns: (options) =>
        CallService(GET, `${BASE_URL}/reports/type_campaigns`, options),
      transactions: (options) =>
        CallService(GET, `${BASE_URL}/reports/transactions`, options),
      campaign: (options) =>
        CallService(GET, `${BASE_URL}/reports/campaigns`, options),
      campaign_overview: (options) =>
        CallService(GET, `${BASE_URL}/reports/campaign_overview`, options),
      brand: (options) =>
        CallService(GET, `${BASE_URL}/reports/brand`, options),
      agency: (options) =>
        CallService(GET, `${BASE_URL}/reports/agency`, options),
      monthly_sale: (options) =>
        CallService(GET, `${BASE_URL}/reports/monthly_sale`, options),
      agency_brands: (options) =>
        CallService(GET, `${BASE_URL}/reports/agency_brands`, options),
    },
    agency: {
      get: (options) => CallService(GET, `${BASE_URL}/agency`, options),
      get_app_setup: (options) =>
        CallService(GET, `${BASE_URL}/agency/get_app_setup`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/agency`, options),
      getProducts: (options) =>
        CallService(GET, `${BASE_URL}/agency/getproducts`, options),
    },
    profile: {
      get: (options) => CallService(GET, `${BASE_URL}/profile`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/profile`, options),
      create: (options) => CallService(POST, `${BASE_URL}/profile`, options),
    },
    chat: {
      get: (options) => CallService(GET, `${BASE_URL}/chat/`, options),
      getByAgency: (options) =>
        CallService(GET, `${BASE_URL}/chat/getbyagency`, options),
      filter: (options) => CallService(GET, `${BASE_URL}/chat/filter`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/chat`, options),
      delete: (options) =>
        CallService(POST, `${BASE_URL}/chat/delete`, options),
      create: (options) => CallService(POST, `${BASE_URL}/chat`, options),
    },
    messages: {
      get: (options) => CallService(GET, `${BASE_URL}/messages`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/messages/filter`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/messages`, options),
      delete: (options) =>
        CallService(POST, `${BASE_URL}/messages/delete`, options),
      create: (options) => CallService(POST, `${BASE_URL}/messages`, options),
    },
    webhook: {
      get: (options) => CallService(GET, `${BASE_URL}/webhook/`, options),
      checkout: (options) =>
        CallService(GET, `${BASE_URL}/webhook/checkout`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/webhook/filter`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/webhook`, options),
      delete: (options) =>
        CallService(POST, `${BASE_URL}/webhook/delete`, options),
      create: (options) => CallService(POST, `${BASE_URL}/webhook`, options),
      SaveSSOData: (options) => CallService(POST, `${BASE_URL}/webhook/sso_data`, options),
    },
    tickets: {
      get: (options) => CallService(GET, `${BASE_URL}/tickets`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/tickets/filter`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/tickets`, options),
      delete: (options) =>
        CallService(POST, `${BASE_URL}/tickets/delete`, options),
      create: (options) => CallService(POST, `${BASE_URL}/tickets`, options),
    },
    comments: {
      get: (options) => CallService(GET, `${BASE_URL}/comments`, options),
      delete: (options) =>
        CallService(POST, `${BASE_URL}/comments/delete`, options),
      create: (options) => CallService(POST, `${BASE_URL}/comments`, options),
    },
    docs: {
      get: (options) => CallService(GET, `${BASE_URL}/docs`, options),
      filter: (options) => CallService(GET, `${BASE_URL}/docs/filter`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/docs`, options),
      delete: (options) =>
        CallService(POST, `${BASE_URL}/docs/delete`, options),
      create: (options) => CallService(POST, `${BASE_URL}/docs`, options),
    },
    docs_category: {
      get: (options) => CallService(GET, `${BASE_URL}/docs/category`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/docs/category/filter`, options),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/docs/category`, options),
      delete: (options) =>
        CallService(POST, `${BASE_URL}/docs/category/delete`, options),
      create: (options) =>
        CallService(POST, `${BASE_URL}/docs/category`, options),
    },
    location: {
      get: (options) => CallService(GET, `${BASE_URL}/location`, options),
      user_locations: (options) =>
        CallService(GET, `${BASE_URL}/location/user_locations`, options),
    },
    business: {
      get: (options) => CallService(GET, `${BASE_URL}/business`, options),
      by_agency: (options) =>
        CallService(GET, `${BASE_URL}/business/by_agency`, options),
      by_location: (options) =>
        CallService(GET, `${BASE_URL}/business/by_location`, options),
    },
    design: {
      get: (options) => CallService(GET, `${BASE_URL}/design`, options),
      create: (options) => CallService(POST, `${BASE_URL}/design`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/design`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/design`, options),
    },
    // new
    ghlDashboardButton: {
      get: (options) =>
        CallService(GET, `${BASE_URL}/ghldashboardbutton`, options),
      create: (options) =>
        CallService(POST, `${BASE_URL}/ghldashboardbutton`, options),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/ghldashboardbutton`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/ghldashboardbutton`, options),
    },
    defaultpage: {
      get: (options) => CallService(GET, `${BASE_URL}/defaultpage`, options),
      create: (options) =>
        CallService(POST, `${BASE_URL}/defaultpage`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/defaultpage`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/defaultpage`, options),
    },
    campaign: {
      get: (options) => CallService(GET, `${BASE_URL}/campaign`, options),
      create: (options) => CallService(POST, `${BASE_URL}/campaign`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/campaign`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/campaign`, options),
      passes: (options) =>
        CallService(GET, `${BASE_URL}/campaign/passes`, options),
    },
    public: {
      get: (options) => CallService(GET, `${BASE_URL}/public`, options),
      enrollment: (options) =>
        CallService(POST, `${BASE_URL}/public/enrollment`, options),
      enroll_now: (options) =>
        CallService(POST, `${BASE_URL}/public/enrollnow`, options),
      get_enrollment: (options) =>
        CallService(GET, `${BASE_URL}/public/enrollment`, options),
      pass: (options) => CallService(GET, `${BASE_URL}/public/pass`, options),
      passes: (options) =>
        CallService(GET, `${BASE_URL}/public/passes`, options),
      subscriber: (options) =>
        CallService(GET, `${BASE_URL}/public/subscriber`, options),
    },
    pass: {
      get: (options) => CallService(GET, `${BASE_URL}/pass`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/pass`, options),
    },
    growthtool: {
      get: (options) => CallService(GET, `${BASE_URL}/growthtool`, options),
      create: (options) => CallService(POST, `${BASE_URL}/growthtool`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/growthtool`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/growthtool`, options),
    },
    redemption: {
      redeem: (options) => CallService(POST, `${BASE_URL}/redemption`, options),
      giveaway: (options) =>
        CallService(POST, `${BASE_URL}/redemption/giveaway`, options),
    },
    mailgun: {
      getdomains: (options) =>
        CallService(GET, `${BASE_URL}/mailgun/emails`, options),
    },
    // ghl: {
    //   call   : (options) => CallService(POST, `${BASE_URL}/services/ghl`, options),
    //   oauth : (options) => CallService(GET, `${BASE_URL}/services/ghl/oauth/callback`, options),
    // },
    stripe: {
      get: (options) =>
        CallService(GET, `${BASE_URL}/services/stripe`, options),
      product: (options) =>
        CallService(GET, `${BASE_URL}/services/stripe/products`, options),
      integrateAcocunt: (options) =>
        CallService(GET, `${BASE_URL}/services/stripe/integrate`, options),
    },
    super_admin: {
      get: (options) => CallService(GET, `${BASE_URL}/super_admin/`, options),
      getproducts: (options) =>
        CallService(GET, `${BASE_URL}/super_admin/getproducts`, options),
      add_team: (options) =>
        CallService(GET, `${BASE_URL}/super_admin/add_team`, options),
      agencies: (options) =>
        CallService(GET, `${BASE_URL}/super_admin/agencies`, options),
      customers: (options) =>
        CallService(GET, `${BASE_URL}/super_admin/customers`, options),
    },
    app: {
      get: (options) => CallService(GET, `${BASE_URL}/apps`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/apps`, options),
      create: (options) => CallService(POST, `${BASE_URL}/apps`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/apps`, options),
      filter: (options) => CallService(GET, `${BASE_URL}/apps/filter`, options),
    },
    upsales: {
      makecharge: (options) =>
        CallService(POST, `${BASE_URL}/upsales/makecharge`, options),
      get: (options) => CallService(GET, `${BASE_URL}/upsales`, options),
    },
    product: {
      get: (options) => CallService(GET, `${BASE_URL}/product`, options),
      create: (options) => CallService(POST, `${BASE_URL}/product`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/product`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/product`, options),
    },
    utils: {
      upload_image: (options) =>
        CallService(POST, `${BASE_URL}/utils/upload/image`, options),
    },
    services: {
      ghl: {
        call_service: (options) =>
          CallService(POST, `${BASE_URL}/services/ghl/`, options),
      },
    },
    ghl: {
      call: (options) => CallService(POST, `${BASE_URL}/services/ghl`, options),
      refresh_token: (options) =>
        CallService(POST, `${BASE_URL}/services/ghl/refresh_token`, options),
    },
  };

  return APIs;
}

const useUploadImage = () => {
  const AppService = useAppServices();
  return ({ toaster, file, desiredPath }) => {
    const form = new FormData();
    form.append("image", file, file.name);
    return AppService.utils.upload_image({
      toaster,
      payload: form,
      query: `desiredPath=${desiredPath}`,
    });
  };
};

export { useAppServices, useCallService, useUploadImage };
