import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { FaBars } from 'react-icons/fa'
import UpdateButton from './UpdateButton'

const Main = ({ handleModalType, buttonData, modalType, handleRefresh }) => {
    const [selectedButton, setSelectedButton] = useState(null)
    const [updateModalOpen, setUpdateModalOpen] = useState(false)
    const handleButton = (button) => {
        console.log(button, 'buttonbutton')
        setUpdateModalOpen(true);
        setSelectedButton(button)
    }
    const handleContentType = (type) => {
        setUpdateModalOpen(false);
        handleModalType(type)
    }
    console.log(buttonData, 'buttonDatabuttonData')

    return (
        <div className="row">
            {
                !updateModalOpen ?
                    <div
                        className="col-lg-12"
                    >
                        <div className="text-center">
                            <div
                                style={{
                                    textAlign: "center",
                                    fontSize: "8pt",
                                    padding: "4px",
                                    fontWeight: "bold",
                                    background: "rgb(226, 229, 235)",
                                    border: "none",
                                    color: "rgb(53, 90, 195)",
                                    marginBottom: "10px",
                                    width: "100%",
                                }}
                            >
                                Contact/Conversation Page
                            </div>
                            {buttonData.map((button, index) => (
                                <>
                                    <Button
                                        onClick={() => handleButton(button)}
                                        key={button._id}
                                        variant="success"
                                        style={{
                                            backgroundColor: button?.button_data?.colorPicker,
                                            margin: "2px 5px",
                                        }}
                                    >
                                        {button?.button_data?.text}
                                    </Button>
                                </>
                            ))}
                            <div className="mt-3 d-flex justify-content-center align-items-center gap-2">
                                <div
                                    onClick={() => handleModalType("add")}
                                    style={{
                                        cursor: "pointer",
                                        color: "blue",
                                        textDecoration: "underline",
                                    }}
                                >
                                    Add Button
                                </div>
                            </div>

                        </div>
                    </div>
                    :
                    <UpdateButton handleModalType={handleContentType} handleRefresh={handleRefresh} editData={selectedButton} />
            }
        </div>

    )
}

export default Main