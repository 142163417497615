import React, { useEffect, useState } from "react";

import {
  Modal,
  Button,
  Form,
} from "react-bootstrap";
import { useAppServices } from "../../../../../hook/services";
import MainContent from "./components/MainContent"
import AddButton from "./components/AddButton";
function ContactButtons({ sso_cred }) {
  const [show, setShow] = useState(false);
  const [modalType, setmodalType] = useState("main");
  const AppService = useAppServices();
  const [buttonData, setButtonData] = useState([]);
  const handleModalType = (type) => {
    setmodalType(type)
  }
  const fetchData = async () => {
    const { response } = await AppService.contactButtons.get({
      query: `company_id=${sso_cred.companyId}&type=contact_app`,
    });
    if (response) {

      setButtonData(response?.data);
    } else {
      setButtonData([]);
    }
  };
  const onLoad = () => {
    fetchData()
  }
  useEffect(() => {
    onLoad();
  }, []);
  return (
    <>
      <Button variant="primary" onClick={() => setShow(true)}>
        Contact Buttons
      </Button>

      <Modal show={show} onHide={() => setShow(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Contact Buttons</Modal.Title>

        </Modal.Header>
        <Modal.Body style={{ height: "100vh", overflowY: "auto" }}>
          {modalType == "main" && (
            <MainContent handleModalType={handleModalType} buttonData={buttonData} modalType={modalType} handleRefresh={onLoad} />
          )}
          {modalType == "add" && (
            <AddButton handleModalType={handleModalType} handleRefresh={onLoad} sso_cred={sso_cred} />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ContactButtons;
