import React, { useState } from "react";
import {
  Button,
  Accordion,
  Card,
  Modal,
  Form,
  useAccordionButton,
} from "react-bootstrap";
import imageurl from "../../../../../../assets/img/image.png";

const itemImages = {
  "Contact Button on Edit Opportunity": imageurl,
  "Opportunity Tags": "image-url-2",
};

const initialFeaturesData = [
  {
    title: "Opportunity Pipelines",
    count: "0/7",
    items: [
      { name: "Contact Button on Edit Opportunity", isNew: false },
      { name: "Opportunity Tags", isNew: false },
      { name: "Call Button on Modal", isNew: true },
      { name: "Show Date in Opportunity Cards", isNew: false },
      { name: "Compressed Opportunity View", isNew: false },
      { name: "Enable Click and Drag in Opportunities", isNew: false },
      { name: "Collapse Pipeline", isNew: true },
    ],
  },
  {
    title: "Contact Details",
    count: "0/5",
    items: [
      { name: "Contact Details UI Fixes (Save Btn, Date Fix)", isNew: false },
      { name: "Enable Drive Links & Open in New Window", isNew: false },
      { name: "Snooze Contacts", isNew: false },
      { name: "View Map Buttons in Contacts", isNew: false },
      { name: "Address Auto Complete", isNew: false },
    ],
  },
  {
    title: "Recent Releases",
    count: "0/8",
    items: [
      { name: "Contact Details - Auto Save", isNew: false },
      { name: "Contacts Search", isNew: false },
      { name: "User Last Login & Auto Logout", isNew: false },
      { name: "Form & Survey Link Shortcut", isNew: false },
      { name: "Protect and Hide Custom Field Folders", isNew: true },
      { name: "Tasks Count", isNew: true },
      { name: "User Import/Export Contacts", isNew: true },
      { name: "Twilio & Mailgun Integrations", isNew: true },
    ],
  },
  {
    title: "Entire System",
    count: "0/9",
    items: [
      { name: "Custom Favicon", isNew: false },
      {
        name: "Update Title Tag with Page, Notifications & Location",
        isNew: false,
      },
      { name: "Enable Notification Settings and Sounds", isNew: false },
      { name: "Logo Click goes to Dashboard", isNew: false },
      { name: "Disable Twillio Warning", isNew: false },
      {
        name: "Add Search to Custom Values, Custom Fields & Message Templates",
        isNew: false,
      },
      { name: "User Permissions - Import/Export", isNew: true },
      { name: "Hide the Location Switch", isNew: true },
      { name: "Blur Contact Details for Demos", isNew: true },
    ],
  },
  {
    title: "Conversation View",
    count: "0/3",
    items: [
      { name: "Email Statistics in Conversation View", isNew: false },
      { name: "Messaging Upgrades", isNew: true },
      { name: "Unread Count", isNew: true },
    ],
  },
  {
    title: "Legacy",
    count: "0/3",
    items: [
      { name: "Turn Trigger Folders On/Off", isNew: false },
      { name: "Turn Campaign Folders On/Off", isNew: false },
      { name: "Protect and Hide Folders", isNew: false },
    ],
  },
  {
    title: "Calendars",
    count: "0/1",
    items: [{ name: "Calendar Tags", isNew: true }],
  },
  {
    title: "Smart Lists",
    count: "0/2",
    items: [
      { name: "Contact Smart List Upgrades", isNew: true },
      { name: "Save Import Field Mappings for Contacts", isNew: true },
    ],
  },
  {
    title: "Only If You Need It",
    count: "0/3",
    items: [
      { name: "Convert Websites & Funnels", isNew: true },
      { name: "Check for Expired FB Integrations", isNew: false },
      { name: "Agency User, Assign to Multiple Locations", isNew: true },
    ],
  },
];

function FeaturesModal() {
  const [showModal, setShowModal] = useState(false);
  const [featuresData, setFeaturesData] = useState(initialFeaturesData);
  const [selectedFeatureIndex, setSelectedFeatureIndex] = useState(null);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const toggleFeature = () => {
    if (selectedFeatureIndex !== null) {
      const newData = featuresData.map((category, catIndex) => {
        if (catIndex === selectedFeatureIndex.categoryIndex) {
          return {
            ...category,
            items: category.items.map((item, itemIndex) => {
              if (itemIndex === selectedFeatureIndex.featureIndex) {
                return { ...item, isEnabled: !item.isEnabled };
              }
              return item;
            }),
          };
        }
        return category;
      });
      setFeaturesData(newData);
    }
  };

  const handleSelectFeature = (categoryIndex, featureIndex) => {
    setSelectedFeatureIndex({ categoryIndex, featureIndex });
  };

  function CustomToggle({ children, eventKey, callback }) {
    const categoryIndex = parseInt(eventKey, 10);
    const enabledCount = featuresData[categoryIndex].items.filter(
      (item) => item.isEnabled
    ).length;
    const totalCount = featuresData[categoryIndex].items.length;

    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey)
    );

    return (
      <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}} type="button" onClick={decoratedOnClick}>

        <span>{children}</span>
        <span
          style={{ marginLeft: "10px",fontWeight:"600" }}
        >{`${enabledCount}/${totalCount} Enabled`}</span>
      </div>
    );
  }

  return (
    <div className="features-modal-container">
      <Button variant="primary" onClick={openModal}>
        Features
      </Button>

      <Modal show={showModal} onHide={closeModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Features Customizer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-5">
              <Accordion defaultActiveKey="0">
                {featuresData.map((category, categoryIndex) => (
                  <Card key={categoryIndex} style={{border:"none"}}>
                    <Card.Header>
                      <CustomToggle  eventKey={String(categoryIndex)}>
                        <h6>{category.title}</h6>
                      </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={String(categoryIndex)}>
                      <Card.Body>
                        <ul>
                          {category.items.map((item, featureIndex) => (
                            <li
                              key={featureIndex}
                              onClick={() =>
                                handleSelectFeature(categoryIndex, featureIndex)
                              }
                              style={{
                                color: item.isEnabled ? "green" : "black",
                                cursor: "pointer",  
                              }}
                            >
                              <span>{item.name}</span>
                             
                            </li>
                          ))}
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))}
              </Accordion>
            </div>
            <div className="col-7">
              {selectedFeatureIndex !== null && (
                <div >
                  <Form.Check
                    type="switch"
                    id="feature-enable-switch"
                    label={`Enable ${
                      featuresData[selectedFeatureIndex.categoryIndex].items[
                        selectedFeatureIndex.featureIndex
                      ].name
                    }`}
                    checked={
                      featuresData[selectedFeatureIndex.categoryIndex].items[
                        selectedFeatureIndex.featureIndex
                      ].isEnabled
                    }
                    onChange={toggleFeature}
                    disabled={false} // Set to false for default enabled
                  />
                  {/* Display the image */}
                  <div>
                  <img
                    src={
                      itemImages[
                        featuresData[selectedFeatureIndex.categoryIndex].items[
                          selectedFeatureIndex.featureIndex
                        ].name
                      ]
                    }
                    alt={`Image for ${
                      featuresData[selectedFeatureIndex.categoryIndex].items[
                        selectedFeatureIndex.featureIndex
                      ].name
                    }`}
                    style={{
                      height: "auto",
                      width: "100%",
                     
                    }}
                  />
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary"  onClick={closeModal} >
            Save
          </Button>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default FeaturesModal;
