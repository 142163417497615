import React from 'react'
import ContactButtonModal from "../Modals/ContactButton"
import { Button } from 'react-bootstrap'
import { useAppServices } from '../../../../../hook/services'
import EditGroupModal from "../NewLevel/components/Modals/Edit"
const AgencyLevel = ({ group, handleRefresh }) => {
    const ApppService = useAppServices()
    const handleDeleteButton = async (indexToRemove) => {
        let contact_buttons = group?.contact_buttons || []
        if (indexToRemove > -1 && indexToRemove < contact_buttons.length) {
            contact_buttons.splice(indexToRemove, 1);
        }
        const payload = {
            _id: group?._id,
            contact_buttons: contact_buttons,
        }
        console.log(payload, 'payloadpayload')
        const { response } = await ApppService.contact_buttons_group.update({ payload });
        if (response) {
            handleRefresh()
        }
    }
    return (
        <div
            className="shadow-sm p-3 mb-4 bg-white rounded "
            style={{ minHeight: "270px", height: '100%' }}
        >
            <div className="d-flex h-100 flex-column align-items-space-between">
                <div className="d-flex flex-column gap-3">
                    <div className="d-flex justify-content-between">

                        <span className="fw-bold text-muted">{group?.name}</span>
                        <EditGroupModal />
                    </div>
                    <span className="mb-2"> {group.locations.map((location) => <p>{`${location.name},`}</p>)} </span>
                    {group?.contact_buttons?.length ? (
                        group.contact_buttons.map((button, index) => (
                            <div className="mb-1 d-flex justify-content-between align-items-center w-100">
                                <Button
                                    key={button._id}
                                    variant="success"
                                    style={{
                                        backgroundColor: button?.button_data?.colorPicker,
                                        margin: "2px 5px",
                                    }}
                                >
                                    {button?.button_data?.text}
                                </Button>
                                <span
                                    onClick={() =>
                                        handleDeleteButton(index)
                                    }
                                    style={{ cursor: "pointer", fontSize: "18px" }}
                                >
                                    <i className="ri-close-circle-line"></i>
                                </span>
                            </div>
                        ))
                    ) : (
                        <span>You haven't added any button yet</span>
                    )}
                </div>
                <div className="mt-auto">
                    <ContactButtonModal group={group} handleRefresh={handleRefresh} />
                </div>
            </div>
        </div>
    )
}

export default AgencyLevel