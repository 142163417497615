import React, { useState } from "react";
import { Modal, Button, Form, FormControl, InputGroup } from "react-bootstrap";
import { FaBell } from "react-icons/fa";

const UpdateContactButton = ({ onReturn }) => {
  const [tooltipText, setTooltipText] = useState("");
  const handleSave = () => {
    onReturn();
  };

  const handleCancel = () => {
    onReturn();
  };

  return (
    <>
      <Button variant="success">Preview</Button>
      <Form>
        <Form.Group controlId="showText">
          <Form.Check type="checkbox" label="Show Text in Button" />
        </Form.Group>
        <Form.Group controlId="tagIndicator">
          <Form.Check type="checkbox" label="Tag Indicator" />
        </Form.Group>
        <Form.Group controlId="tooltip">
          <Form.Label>Tooltip (HTML Allowed)</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={tooltipText}
            onChange={(e) => setTooltipText(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formIcon">
          <Form.Label>Icon</Form.Label>
          <InputGroup>
            <InputGroup.Text>
              <FaBell />
            </InputGroup.Text>
            <FormControl type="text" placeholder="fas fa-bell" />
          </InputGroup>
        </Form.Group>
        <Form.Group>
          <Form.Label>CSS Selector</Form.Label>
          <FormControl placeholder="CSS Selector" />
        </Form.Group>
        <Form.Group controlId="formColor">
          <Form.Check type="checkbox" label="Color" checked />
          <div className="input-group colorpicker">
            <input type="color" className="form-control" />
          </div>
        </Form.Group>
        {/* <Form.Group controlId="permissions">
          <Form.Label>Permissions</Form.Label>
          <Form.Check type="checkbox" label="Agency Admins" />
          <Form.Check type="checkbox" label="Agency Users" />
          <Form.Check type="checkbox" label="Account Admins" />
          <Form.Check type="checkbox" label="Account Users" />
        </Form.Group> */}
      </Form>
      <Modal.Footer>
        <Button variant="danger">Delete</Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
      </Modal.Footer>
    </>
  );
};

export default UpdateContactButton;
