import React, { useState, useEffect } from "react";

import {
  Modal,
  Button,
  Form,
  Tab,
  Tabs,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { FaBell } from "react-icons/fa";
const { useAppServices } = require("../../../../../../../hook/services");
function AddButton({
  onReturn,
  type,
  fetchData,
  buttonData,
  updateButton,
  sso_cred,
  isgroup,
  handleMenus,
}) {
  console.log(buttonData, "buttonData");
  const AppService = useAppServices();
  const handlePermissionsChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = checked
    setFormData({ ...formData, user_permissions: { ...formData.user_permissions, [name]: val } });
};
  const handleDeleteButton = async (id) => {
    console.log("id", id)
    if (isgroup) {
      handleMenus(null, "contact_buttons", "delete", buttonData?.index)
    } else {

      const { response } = await AppService.contactButtons.delete({
        query: `_id=${id}`,
      });
      console.log("response", response)
    }
    fetchData()
    onReturn()
  }

  const [formData, setFormData] = useState({
    folder: "",
    text: "",
    tooltip: "",
    icon: "",
    color: false,
    cssSelector: "",
    user_permissions: {
      agency_admins: true,
      agency_users: true,
      account_admins: true,
      account_users: true,
    },
    tags: "",
    enableToggle: false,
    monitorChanges: false,
    readUnreadOption: "",
    starredOption: "",
    archiveConversation: false,
    confirmationRequired: false,
    linkOrCustomValue: "",
    lockToContact: false,
    contactDataSent: "",
    sendContact: true,
    viewType: "",
    customFields: true,
  });
  function setButtonData() {
    console.log(buttonData, "buttonData");
    if (buttonData) {
      setFormData(buttonData.button_data);
      console.log(formData, "formData");
    }
  }

  useEffect(() => {
    setButtonData();
  }, [buttonData]);
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      if (
        name === "agency_admins" ||
        name === "agency_users" ||
        name === "account_admins" ||
        name === "account_users"
      ) {
        setFormData((prevState) => ({
          ...prevState,
          permissions: {
            ...prevState.permissions,
            [name]: checked,
          },
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          [name]: checked,
        }));
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };


  const handleSave = async () => {
    console.log(type);
    console.log(formData,"as@asdfasdf ");

    // return 0;
    if (updateButton) {
      const payload = {
        _id: buttonData?._id,
        company_id: sso_cred?.companyId,
        type: type,
        button_data: formData,
      };
      if (isgroup) {
        handleMenus(payload, "contact_buttons", "edit", buttonData?.index)
      } else {
        const { response } = await AppService.contactButtons.update({
          payload: payload,
        });
        console.log(response, "response updates");
      }
    } else {
      const payload = {
        company_id: sso_cred.companyId,
        type: type,
        button_data: formData,
      };
      if (isgroup) {
        handleMenus(payload, "contact_buttons")
      } else {
        const { response } = await AppService.contactButtons.create({
          payload: payload,
        });
        console.log(response, "response create");
      }
    }

    fetchData();
    onReturn();
  };

  const handleCancel = () => {
    onReturn();
  };

  // const handleChange = (e) => {
  //   const { id, value, type, checked } = e.target;
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     [id]: type === "checkbox" ? checked : value,
  //   }));
  // };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6">
          <Form>
            <div className="preview-area text-center mb-3">
              <Button variant="success">
                <FaBell className="mr-2" />
                Sample Button
              </Button>
            </div>
            {/* <Form.Group controlId="formFolder">
              <Form.Label>Folder</Form.Label>
              <Form.Control
                as="select"
                onChange={handleChange}
                name="folder"
                value={formData.folder}
              >
                <option>Header - Folder name</option>
                <option>Folder name</option>
              </Form.Control>
            </Form.Group> */}
            <Form.Group controlId="formText">
              <Form.Label>Text</Form.Label>
              <Form.Control
                id="text"
                type="text"
                placeholder="Sample Button"
                onChange={handleChange}
                name="text"
                value={formData.text}
              />
            </Form.Group>
            <Form.Group controlId="formTooltip">
              <Form.Label>Tooltip (HTML Allowed)</Form.Label>
              <Form.Control
                type="text"
                onChange={handleChange}
                name="tooltip"
                value={formData.tooltip}
              />
            </Form.Group>
            {/* <Form.Group controlId="formIcon">
              <Form.Label>Icon</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <FaBell />
                </InputGroup.Text>
                <FormControl
                  type="text"
                  placeholder="fas fa-bell"
                  onChange={handleChange}
                  name="icon"
                  value={formData.icon}
                />
              </InputGroup>
            </Form.Group> */}
            <Form.Group controlId="formColor">
              <Form.Check
                type="checkbox"
                label="Color"
                checked={formData.color}
                onChange={handleChange}
                name="color"
                value={formData.color}
              />

              <div className="input-group colorpicker">
                <input
                  type="color"
                  className="form-control"
                  onChange={handleChange}
                  name="colorPicker"
                  value={formData.colorPicker}
                />
              </div>
            </Form.Group>
            <Form.Group controlId="formCSSSelector">
              <Form.Label>CSS Selector</Form.Label>
              <Form.Control
                type="text"
                onChange={handleChange}
                name="cssSelector"
                value={formData.cssSelector}
              />
            </Form.Group>
            {/* <Form.Group>
              <Form.Label>Permissions</Form.Label>
              {["checkbox"].map((type) => (
                <div key={`default-${type}`} className="mb-3">
                  <Form.Check
                    type={type}
                    id={`check-api-${type}`}
                    label="Agency Admins"
                    onChange={handleChange}
                    name="agency_admins"
                    value={formData.permissions.agency_admins}
                    checked={formData.permissions.agency_admins}
                  />
                  <Form.Check
                    type={type}
                    id={`check-api-${type}`}
                    label="Agency Users"
                    onChange={handleChange}
                    name="agency_users"
                    value={formData.permissions.agency_users}
                    checked={formData.permissions.agency_users}
                  />
                  <Form.Check
                    type={type}
                    id={`check-api-${type}`}
                    label="Account Admins"
                    onChange={handleChange}
                    name="account_admins"
                    value={formData.permissions.account_admins}
                    checked={formData.permissions.account_admins}
                  />
                  <Form.Check
                    type={type}
                    id={`check-api-${type}`}
                    label="Account Users"
                    onChange={handleChange}
                    name="account_users"
                    value={formData.permissions.account_users}
                    checked={formData.permissions.account_users}
                  />
                </div>
              ))}
            </Form.Group> */}
          </Form>
        </div>
        <div className="col-md-6">
          <Tabs
            defaultActiveKey="link"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            {/* <Tab eventKey="action" title="ACTION">
              <Form>
                <Form.Group controlId="formTags">
                  <Form.Label>Tags</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Comma separated"
                    onChange={handleChange}
                    name="tags"
                    value={formData.tags}
                  />
                </Form.Group>
                <Form.Group controlId="formEnableToggle">
                  <Form.Check
                    type="checkbox"
                    label="Enable Toogle"
                    onChange={handleChange}
                    name="enableToggle"
                    value={formData.enableToggle}
                    checked={formData.enableToggle}
                  />
                </Form.Group>
                <Form.Group controlId="formMonitorChanges">
                  <Form.Check
                    type="checkbox"
                    label="Check for tag changes"
                    onChange={handleChange}
                    name="monitorChanges"
                    value={formData.monitorChanges}
                    checked={formData.monitorChanges}
                  />
                </Form.Group>
                <fieldset>
                  <Form.Group>
                    <Form.Label>Read/Unread</Form.Label>
                    <Form.Check
                      type="radio"
                      label="Read"
                      name="readUnreadOptions"
                      id="readOption"
                      onChange={handleChange}
                      value="read"
                      checked={formData.readUnreadOptions === "read"}
                    />
                    <Form.Check
                      type="radio"
                      label="Unread"
                      name="readUnreadOptions"
                      id="unreadOption"
                      onChange={handleChange}
                      value="unread"
                      checked={formData.readUnreadOptions === "unread"}
                    />
                  </Form.Group>
                </fieldset>
                <fieldset>
                  <Form.Group>
                    <Form.Label>Starred/Unstarred</Form.Label>
                    <Form.Check
                      type="radio"
                      label="Starred"
                      name="starredOptions"
                      id="starredOption"
                      onChange={handleChange}
                      value="starred"
                      checked={formData.starredOptions === "starred"}
                    />
                    <Form.Check
                      type="radio"
                      label="Unstarred"
                      name="starredOptions"
                      id="unstarredOption"
                      onChange={handleChange}
                      value="unstarred"
                      checked={formData.starredOptions === "unstarred"}
                    />
                  </Form.Group>
                </fieldset>
                <Form.Group controlId="formArchiveConversation">
                  <Form.Check
                    type="checkbox"
                    label="Archive Conversation"
                    onChange={handleChange}
                    id="archiveConversation"
                    name="archiveConversation"
                    value={formData.archiveConversation}
                    checked={formData.archiveConversation}
                  />
                </Form.Group>
                <Form.Group controlId="formConfirmationRequired">
                  <Form.Check
                    type="checkbox"
                    label="Confirmation Required"
                    onChange={handleChange}
                    id="confirmationRequired"
                    name="confirmationRequired"
                    checked={formData.confirmationRequired}
                    value={formData.confirmationRequired}
                  />
                </Form.Group>
              </Form>
            </Tab> */}
            <Tab eventKey="link" title="LINK">
              <Form>
                <Form.Group controlId="formLinkOrCustomValue">
                  <Form.Label>Link or Custom Value</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="https://"
                    onChange={handleChange}
                    name="linkOrCustomValue"
                    value={formData.linkOrCustomValue}
                  />
                  <Form.Group controlId="sendContactData">
                <Form.Check
                  type="checkbox"
                  name="sendContact"
                  label="Send contact data to URL"
                  checked={formData.sendContact}
                  onChange={handleInputChange}
                />
                <Form.Check
                  type="checkbox"
                  name="customFields"
                  label="Send Custom Fields data to URL"
                  checked={formData.customFields}
                  onChange={handleInputChange}
                />
              </Form.Group>


              <Form.Group className="mb-3" controlId="formLink">
                            <Form.Label>User Permissions</Form.Label>
                            <Form.Check
                                checked={formData.user_permissions.agency_admins}
                                type="checkbox"
                                label="Agency Admins"
                                name="agency_admins"
                                onChange={handlePermissionsChange}
                            />
                            <Form.Check
                                checked={formData.user_permissions.agency_users}
                                type="checkbox"
                                label="Agency Users"
                                name="agency_users"
                                onChange={handlePermissionsChange}
                            />
                            <Form.Check
                                checked={formData.user_permissions.account_admins}
                                type="checkbox"
                                label="Account Admins"
                                name="account_admins"
                                onChange={handlePermissionsChange}
                            />
                            <Form.Check
                                checked={formData.user_permissions.account_users}
                                type="checkbox"
                                label="Account Users"
                                name="account_users"
                                onChange={handlePermissionsChange}
                            />
                        </Form.Group>





                  {/* <Form.Text className="text-muted">
                    Must contain https:// (Works with custom fields & values)
                  </Form.Text> */}
                </Form.Group>
                {/* <Form.Group controlId="formLockToContact">
                  <Form.Check
                    type="checkbox"
                    label="Lock to Contact?"
                    onChange={handleChange}
                    name="lockToContact"
                    value={formData.lockToContact}
                  />
                </Form.Group>
                <Form.Group controlId="formContactDataSent">
                  <Form.Label>Contact Data is sent to the link:</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={handleChange}
                    name="contactDataSent"
                    value={formData.contactDataSent}
                  >
                    <option>Through the API</option>
                    <option>Through the URL</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="formViewType">
                  <Form.Label>View Type</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={handleChange}
                    name="viewType"
                    value={formData.viewType}
                  >
                    <option>Open in New Window</option>
                  </Form.Control>
                </Form.Group> */}
              </Form>
            </Tab>
          </Tabs>
        </div>
      </div>
      <Modal.Footer className="mt-3 d-flex justify-content-end">
        <Button variant="secondary" className="mr-2" onClick={handleCancel}>
          Cancel
        </Button>

        {updateButton ? (
          <Button variant="danger" className="mr-2" onClick={() => handleDeleteButton(buttonData._id)}>
            Delete
          </Button>
        ) : (
          ""
        )}

        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </div>
  );
}

export default AddButton;
