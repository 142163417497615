import { useState } from "react"
import { Button, Dropdown, Form, Modal } from "react-bootstrap"
import Select from "react-select";
import HeaderButtons from "../HeaderButtons"
import OpportunityTabsModal from "../Opportunity"
import DashboardButtonModal from "../DashboardButtons"
import LaunchpadModal from "../LaunchPad"
import ContactButtons from "../ContactButtons"
import SettingMenuModel from "../SettingMenu"

function InstallationModal({ saveLocation, ghl_locations, edit_data, DeleteNewLevel, sso_cred }) {
    const [open, setOpen] = useState(false)
    const [refresh, setrefresh] = useState(false);
    const [selectedLocations, setSelectedLocations] = useState(edit_data?.locations || [""]);

    const removeLocation = (Index) => {
        console.log(Index, 'Index')
        const data = selectedLocations
        console.log(data, 'data')
        data.splice(Index, 1);
        console.log(data, 'splicedata')

        setSelectedLocations(data);
        setrefresh(!refresh)
    };
    const editLocation = (Index, value) => {
        const data = selectedLocations

        data[Index] = value;
        setSelectedLocations(data);
        setrefresh(!refresh)

    }

    const handleSubmit = (e) => {
        
        e.preventDefault()
        console.log(selectedLocations,"setlocatiojsns")
        const locationsWithIds = {
            ...edit_data,
            name: e.target.name.value,
            enable_menu_edits: e.target.enable_menu_edits.checked,
            enable_header_buttons: e.target.enable_header_buttons.checked,
            enable_dashboard_buttons: e.target.enable_dashboard_buttons.checked,
            enable_opportunity_tabs: e.target.enable_opportunity_tabs.checked,
            enable_launchpad: e.target.enable_launchpad.checked,
            enable_contact_buttons: e.target.enable_contact_buttons.checked,
            enable_setting_menu: e.target.enable_setting_menu.checked,
            locations: selectedLocations,
            _id: edit_data._id
        };
        console.log("Locations with IDs:", locationsWithIds);
        saveLocation(locationsWithIds);
        // setSelectedLocations([]);
        handleClose()
    };
    const handleMenus = (data, type, action, index) => {
        console.log(type, 'type')
        console.log(action, 'action')
        console.log(index, 'index')
        const payload = {
            ...edit_data,
            _id: edit_data._id
        };
        if (action == "edit") {
            payload[type][index] = data

        } else if (action == "delete") {
            payload[type].splice(index, 1);

        } else if (action == "reset") {
            payload[type] = data
        } else {
            const temp_data = payload[type] || []
            payload[type] = [...temp_data, data]
        }
        console.log("payload", payload);
        saveLocation(payload);
        handleClose()
    };
    const handleClose = () => {
        setOpen(false)
    }
    return (
        <>
            <div className="d-flex align-items-center">
                <Dropdown>
                    <Dropdown.Toggle variant="white" id="dropdown-basic" className="border-0 p-0 m-0 ml-2">
                        <i className="ri-edit-2-fill" style={{ cursor: 'pointer', marginLeft: "10px" }} ></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu >
                        <div className="mb-2">
                            <HeaderButtons headerButtons={edit_data?.header_buttons || []} sso_cred={sso_cred} isgroup={true} handleMenus={handleMenus} type="custom" editData={edit_data} />
                        </div>
                        <div className="mb-2">
                        
                            <DashboardButtonModal sso_cred={sso_cred} isgroup={true} handleMenus={handleMenus} type="custom" editData={edit_data?.dashboard_button || []} />
                        </div>
                        <div className="mb-2">
                            <OpportunityTabsModal sso_cred={sso_cred} isgroup={true} handleMenus={handleMenus} editData={edit_data?.opportunity_tabs || []} />
                        </div>
                        <div className="mb-2">
                            <LaunchpadModal sso_cred={sso_cred} isgroup={true} handleMenus={handleMenus} editData={edit_data?.launchpads || []} />
                        </div>
                        <div className="mb-2">
                            <ContactButtons sso_cred={sso_cred} isgroup={true} handleMenus={handleMenus} editData={edit_data?.contact_buttons || []} />
                        </div>
                        <div className="mb-2">
                            <SettingMenuModel sso_cred={sso_cred} isgroup={true} handleMenus={handleMenus} editData={edit_data?.setting_menus || []} />
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
                <i className="ri-edit-2-fill" style={{ cursor: 'pointer', marginLeft: "10px" }} onClick={() => setOpen(true)}></i>
                <i className="ri-delete-bin-6-fill " style={{ cursor: 'pointer', marginLeft: "10px" }} onClick={DeleteNewLevel}></i>
            </div>

            {/* <Button variant="primary" onClick={() => setOpen(true)}>
                Edit
            </Button> */}
            <Modal show={open} onHide={handleClose} centered>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Group</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="formLocationID">
                            <Form.Label>Group Name</Form.Label>
                            <Form.Control
                                defaultValue={edit_data?.name}
                                type="text"
                                placeholder="Enter Location Name"
                                name="name"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formLocationID">
                            <Form.Label>Locations</Form.Label>
                            <Select defaultValue={edit_data?.locations} options={ghl_locations} isSearchable={false} isMulti onChange={(e) => setSelectedLocations(e)} />
                            {/* {
                                selectedLocations.map((location, index) =>
                                    <>
                                        <div className="d-flex gap-2 align-items-center">

                                            <Form.Control
                                                className="mb-2"
                                                type="text"
                                                placeholder="Enter Location ID"
                                                name="location_id"
                                                value={location}
                                                onChange={(e) => editLocation(index, e.target.value)}
                                            />
                                            <span style={{ cursor: 'pointer', fontSize: '18px' }}
                                                onClick={() => removeLocation(index)}
                                            ><i className="ri-close-circle-line"></i>
                                            </span>
                                        </div>
                                    </>
                                )
                            }
                            <div className="d-flex justify-content-start mt-2">

                                <Button variant="primary" type="button" onClick={() => setSelectedLocations([...selectedLocations, ''])}>
                                    + Add Location Id
                                </Button>
                            </div> */}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formLink">
                            <Form.Check
                                defaultChecked={edit_data?.enable_menu_edits}
                                type="checkbox"
                                label="Enable Menu Edits"
                                name="enable_menu_edits"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formLink">
                            <Form.Check
                                type="checkbox"
                                defaultChecked={edit_data?.enable_header_buttons}
                                label="Enable Header Buttons"
                                name="enable_header_buttons"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formLink">
                            <Form.Check
                                type="checkbox"
                                defaultChecked={edit_data?.enable_dashboard_buttons}
                                label="Enable Dashboard Buttons"
                                name="enable_dashboard_buttons"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formLink">
                            <Form.Check
                                type="checkbox"
                                defaultChecked={edit_data?.enable_opportunity_tabs}
                                label="Enable Opportunity Tabs"
                                name="enable_opportunity_tabs"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formLink">
                            <Form.Check
                                type="checkbox"
                                defaultChecked={edit_data?.enable_launchpad}
                                label="Enable Launchpad"
                                name="enable_launchpad"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formLink">
                            <Form.Check
                                type="checkbox"
                                defaultChecked={edit_data?.enable_contact_buttons}
                                label="Enable Contact Buttons"
                                name="enable_contact_buttons"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formLink">
                            <Form.Check
                                type="checkbox"
                                defaultChecked={edit_data?.enable_setting_menu}
                                label="Enable Setting Menu"
                                name="enable_setting_menu"
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit">
                            Edit
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}
export default InstallationModal