import React, { useState, useEffect } from "react";

import {
  Modal,
  Button,
  Form,
  Tab,
  Tabs,
} from "react-bootstrap";
import { FaBell } from "react-icons/fa";
import { useAppServices } from "../../../../../../hook/services";
function UpdateButton({
  handleModalType,
  handleRefresh,
  editData,
}) {
  const AppService = useAppServices()
  const handleSave = async (e) => {
    e.preventDefault()
    const text = e.target.text.value
    const tooltip = e.target.tooltip.value
    const color = e.target.color.checked
    const cssSelector = e.target.cssSelector.value
    const colorPicker = e.target.colorPicker.value
    const linkOrCustomValue = e.target.linkOrCustomValue.value
    const button_data = {
      text: text,
      tooltip: tooltip,
      color: color,
      colorPicker: colorPicker,
      cssSelector: cssSelector,
      linkOrCustomValue: linkOrCustomValue,
    }
    const payload = {
      _id: editData?._id,
      button_data: button_data,
    }
    console.log(payload, 'payloadpayload')
    const { response } = await AppService.contactButtons.update({ payload });
    if (response) {
      handleModalType("main")
      handleRefresh()
    }
  }
  const handleDelte = async () => {
    const { response } = await AppService.contactButtons.delete({ query: `_id=${editData?._id}` });
    if (response) {
      handleModalType("main")
      handleRefresh()
    }
  }
  return (
    <div className="container-fluid">
      <Form onSubmit={handleSave}>
        <div className="row">
          <div className="col-md-6">
            <div className="preview-area text-center mb-3">
              <Button variant="success">
                <FaBell className="mr-2" />
                Sample Button
              </Button>
            </div>
            {/* <Form.Group controlId="formFolder">
              <Form.Label>Folder</Form.Label>
              <Form.Control
                as="select"
                // onChange={handleChange}
                name="folder"
              // value={formData.folder}
              >
                <option>Header - Folder name</option>
                <option>Folder name</option>
              </Form.Control>
            </Form.Group> */}
            <Form.Group controlId="formText">
              <Form.Label>Text</Form.Label>
              <Form.Control
                id="text"
                type="text"
                placeholder="Sample Button"
                name="text"
                defaultValue={editData?.button_data?.text}
              />
            </Form.Group>
            <Form.Group controlId="formTooltip">
              <Form.Label>Tooltip (HTML Allowed)</Form.Label>
              <Form.Control
                type="text"
                name="tooltip"
                defaultValue={editData?.button_data?.tooltip}

              />
            </Form.Group>
            <Form.Group controlId="formColor">
              <Form.Check
                type="checkbox"
                label="Color"
                name="color"
                defaultChecked={editData?.button_data?.color}
              />

              <div className="input-group colorpicker">
                <input
                  type="color"
                  className="form-control"
                  name="colorPicker"
                  defaultValue={editData?.button_data?.colorPicker}
                />
              </div>
            </Form.Group>
            <Form.Group controlId="formCSSSelector">
              <Form.Label>CSS Selector</Form.Label>
              <Form.Control
                type="text"
                name="cssSelector"
                defaultValue={editData?.button_data?.cssSelector}

              />
            </Form.Group>
          </div>
          <div className="col-md-6">
            <Tabs
              defaultActiveKey="link"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="link" title="LINK">
                <Form.Group controlId="formLinkOrCustomValue">
                  <Form.Label>Link or Custom Value</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="https://"
                    name="linkOrCustomValue"
                    defaultValue={editData?.button_data?.linkOrCustomValue}
                  />
                </Form.Group>
              </Tab>
            </Tabs>
          </div>
        </div>
        <Modal.Footer className="mt-3 d-flex justify-content-end">
          <Button variant="secondary" className="mr-2" onClick={() => handleModalType("main")} >
            Cancel
          </Button>
          <Button variant="danger" className="mr-2" onClick={handleDelte} >
            Delete
          </Button>
          <Button variant="primary" type="submit">
            Save
          </Button>
        </Modal.Footer>
      </Form>

    </div>
  );
}

export default UpdateButton;
