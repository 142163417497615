import React, { useState, useEffect } from "react";

import {
    Modal,
    Button,
    Form,
    Tab,
    Tabs,
} from "react-bootstrap";
import { FaBell } from "react-icons/fa";
import { useAppServices } from "../../../../../../hook/services";
function AddButton({
    handleRefresh,
    group
}) {
    const [open, setOpen] = useState()
    const AppService = useAppServices()
    const handleSave = async (e) => {
        e.preventDefault()
        const text = e.target.text.value
        const tooltip = e.target.tooltip.value
        const color = e.target.color.checked
        const cssSelector = e.target.cssSelector.value
        const colorPicker = e.target.colorPicker.value
        const linkOrCustomValue = e.target.linkOrCustomValue.value
        const button_data = {
            text: text,
            tooltip: tooltip,
            color: color,
            cssSelector: cssSelector,
            colorPicker: colorPicker,
            linkOrCustomValue: linkOrCustomValue,
        }
        const data = {
            button_data: button_data
        }
        let contact_buttons = group?.contact_buttons || []
        contact_buttons.push(data)
        const payload = {
            _id: group?._id,
            contact_buttons: contact_buttons,
        }
        console.log(payload, 'payloadpayload')
        const { response } = await AppService.contact_buttons_group.update({ payload });
        if (response) {
            handleRefresh()
            setOpen(false)
        }
    }
    return (
        <>
            <Button variant="primary" className="w-100" onClick={() => setOpen(true)}>
                Add Contact Button
            </Button>
            <Modal show={open} onHide={() => setOpen(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Contact Button</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: "500px", overflowY: "auto" }}>
                    <Form onSubmit={handleSave}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="preview-area text-center mb-3">
                                    <Button variant="success">
                                        <FaBell className="mr-2" />
                                        Sample Button
                                    </Button>
                                </div>
                                <Form.Group controlId="formText">
                                    <Form.Label>Text</Form.Label>
                                    <Form.Control
                                        id="text"
                                        type="text"
                                        placeholder="Sample Button"
                                        name="text"
                                    // value={formData.text}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formTooltip">
                                    <Form.Label>Tooltip (HTML Allowed)</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="tooltip"
                                    // value={formData.tooltip}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formColor">
                                    <Form.Check
                                        type="checkbox"
                                        label="Color"
                                        name="color"
                                    />

                                    <div className="input-group colorpicker">
                                        <input
                                            type="color"
                                            className="form-control"
                                            name="colorPicker"
                                        />
                                    </div>
                                </Form.Group>
                                <Form.Group controlId="formCSSSelector">
                                    <Form.Label>CSS Selector</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="cssSelector"
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Tabs
                                    defaultActiveKey="link"
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                                >
                                    <Tab eventKey="link" title="LINK">
                                        <Form.Group controlId="formLinkOrCustomValue">
                                            <Form.Label>Link or Custom Value</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="https://"
                                                name="linkOrCustomValue"
                                            />
                                        </Form.Group>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                        {/* <Modal.Footer className="mt-3 d-flex justify-content-end">
                            <Button variant="secondary" className="mr-2" onClick={() => setOpen(false)} >
                                Cancel
                            </Button>

                            <Button variant="primary" type="submit">
                                Save
                            </Button>
                        </Modal.Footer> */}
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setOpen(false)}>
                                Close
                            </Button>
                            <Button variant="primary" type="submit">
                                Save
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AddButton;
