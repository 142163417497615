import React from 'react'
import NewLevelModal from './components/Modals'
const AgencyLevel = ({ handleRefresh, sso_cred, ghlLocations }) => {
    return (
        <div
            className="shadow-sm p-3 mb-4 bg-white rounded "
            style={{ minHeight: "270px", height: '100%' }}
        >
            <div className="d-flex h-100 flex-column align-items-space-between">
                <div className="d-flex flex-column gap-3">
                    <span className="fw-bold text-muted">Add New Group</span>
                </div>
                <div className="mt-auto">
                    <NewLevelModal handleRefresh={handleRefresh} sso_cred={sso_cred} ghlLocations={ghlLocations} />
                </div>
            </div>
        </div>
    )
}

export default AgencyLevel