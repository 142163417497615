import React, { useState, useEffect } from "react";

import {
    Modal,
    Button,
    Form,
} from "react-bootstrap";
import Select from "react-select";
import { useAppServices } from "../../../../../../../hook/services";
function EditNewLevelModal({
    sso_cred,
    handleRefresh,
    ghlLocations
}) {
    const [open, setOpen] = useState(false)
    const [locations, setlocations] = useState([])
    const AppService = useAppServices()
    const handleSubmit = async (e) => {
        e.preventDefault()
        const payload = {
            name: e.target.name.value,
            enable_contact_buttons: e.target.enable_contact_buttons.checked,
            locations: locations,
            company_id: sso_cred?.companyId,
        }
        console.log(payload, 'payloadpayload')
        const { response } = await AppService.contact_buttons_group.create({ payload });
        if (response) {
            handleRefresh()
            setOpen(false)
        }
    }
    return (
        <>
            <i className="ri-edit-2-fill" style={{ cursor: 'pointer', marginLeft: "10px" }} onClick={() => setOpen(true)}></i>

            <Modal show={open} onHide={() => setOpen(false)} centered>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add New Group</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="formLocationID">
                            <Form.Label>Group Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Location Name"
                                name="name"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formLocationID">
                            <Form.Label>Locations</Form.Label>
                            <Select options={ghlLocations} isSearchable={false} isMulti name="locations" onChange={(e) => setlocations(e)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formLink">
                            <Form.Check
                                type="checkbox"
                                label="Enable Contact Buttons"
                                name="enable_contact_buttons"
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setOpen(false)}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit">
                            Save Group
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default EditNewLevelModal;
