import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FaBars } from "react-icons/fa";
import AddButton from "./components/AddButton";
import UpdateContactButton from "./components/UpdateButton";
import { useAppServices } from "../../../../../../hook/services";

function ContactButtons({ sso_cred, isgroup, handleMenus, editData }) {
  const AppService = useAppServices();

  const [show, setShow] = useState(false);
  const [headerFolders, setHeaderFolders] = useState([]);
  const [contactFolders, setContactFolders] = useState([]);
  const [isAddButtonVisible, setIsAddButtonVisible] = useState(false);
  const [type, setType] = useState("");
  const [showUpdateButton, setShowUpdateButton] = useState(false);
  const [headerButtons, setHeaderButtons] = useState([]);
const [contactButtons, setContactButtons] = useState(editData || []);
  const [updateButton, setUpdateButton] = useState(false);
  const [buttonData, setButtonData] = useState(undefined);
  const fetchData = async () => {
    if (isgroup) {
      setContactButtons(editData);

    } else {

      const { response } = await AppService.contactButtons.get({
        query: `company_id=${sso_cred.companyId}`,
      });
      // console.log(response?.data, "App Services");


      if (response) {

        const contacts = response.data.filter((obj) => obj.type === "contact");
        const headers = response.data.filter((obj) => obj.type === "header");
        setHeaderButtons(headers);
        setContactButtons(contacts);
        console.log(contacts, "contacts");
        console.log(headers, "headers");
      }
    }
    // setTabs(response?.data);
  };
  useEffect(() => {
    fetchData();
  }, [sso_cred,editData]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleAddHeaderFolder = () => {
    const newFolders = [...headerFolders, {}];
    setHeaderFolders(newFolders);
  };

  const handleAddContactFolder = () => {
    const newFolders = [...contactFolders, {}];
    setContactFolders(newFolders);
  };

  const handleAddButton = () => {
    setIsAddButtonVisible(!isAddButtonVisible);
    setShowUpdateButton(false);
  };

  const handleUpdateButtonClick = () => {
    setShowUpdateButton(true);
    setIsAddButtonVisible(false);
  };

  const handleReturn = () => {
    console.log("Return Clicked");
    setButtonData(undefined);
    setIsAddButtonVisible(false);
    setShowUpdateButton(false);
    setType("");
    setUpdateButton(false);
  };

  const handleDragStart = (e, folderType, folderIndex) => {
    e.dataTransfer.setData("folderType", folderType);
    e.dataTransfer.setData("folderIndex", folderIndex.toString());
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetFolderType) => {
    const draggedFolderType = e.dataTransfer.getData("folderType");
    const draggedFolderIndex = parseInt(e.dataTransfer.getData("folderIndex"));

    if (draggedFolderType === targetFolderType) return;

    const draggedFolder =
      draggedFolderType === "header"
        ? headerFolders[draggedFolderIndex]
        : contactFolders[draggedFolderIndex];

    if (targetFolderType === "header") {
      const newContactFolders = [...contactFolders];
      newContactFolders.splice(draggedFolderIndex, 1);
      setContactFolders(newContactFolders);
      setHeaderFolders([...headerFolders, draggedFolder]);
    } else {
      const newHeaderFolders = [...headerFolders];
      newHeaderFolders.splice(draggedFolderIndex, 1);
      setHeaderFolders(newHeaderFolders);
      setContactFolders([...contactFolders, draggedFolder]);
    }
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Contact Buttons
      </Button> 

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Contact Buttons</Modal.Title>
          
        </Modal.Header>
        <Modal.Body style={{ height: "100vh", overflowY: "auto" }}>
          {!isAddButtonVisible && !showUpdateButton && (
            <div className="row">

              <div
                className="col-lg-12"
                onDragOver={(e) => handleDragOver(e)}
                onDrop={(e) => handleDrop(e, "contact")}
              >
                <div className="text-center">
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "8pt",
                      padding: "4px",
                      fontWeight: "bold",
                      background: "rgb(226, 229, 235)",
                      border: "none",
                      color: "rgb(53, 90, 195)",
                      marginBottom: "10px",
                      width: "100%",
                    }}
                  >
                    Contact/Conversation Page
                  </div>
                  {contactFolders.map((folder, index) => (
                    <div
                      key={index}
                      className="d-flex flex-column gap-3 align-items-start mt-3 mb-3"
                      draggable
                      onDragStart={(e) => handleDragStart(e, "contact", index)}
                    >
                      <div className="d-flex gap-3">
                        <FaBars
                          size={20}
                          className="mr-2"
                          onClick={handleUpdateButtonClick}
                        />
                        <span>Type: folder</span>
                        <span className="ml-2">Name: Folder name</span>
                      </div>
                      <div
                        onClick={handleAddButton}
                        style={{
                          cursor: "pointer",
                          color: "blue",
                          textDecoration: "underline",
                        }}
                      >
                        Add Button
                      </div>
                    </div>
                  ))}
                  {contactFolders.length === 0 && <div>No Folders Yet</div>}
                  {contactButtons.map((button, index) => (
                    <Button
                      key={button._id}
                      variant="success"
                      style={{
                        backgroundColor: button?.button_data?.colorPicker,
                        margin: "2px 5px",
                      }}
                      onClick={() => {
                        setButtonData({ ...button, index: index });
                        handleAddButton();
                        setType("contact");
                        setUpdateButton(true);
                        // handleEditButtonClick(index);
                        // setOpenButtonId(button._id);
                      }}
                    >
                      {button?.button_data?.text}
                    </Button>
                  ))}
                  <div className="mt-3 d-flex justify-content-center align-items-center gap-2">
                    {/* <div
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        textDecoration: "underline",
                      }}
                      onClick={handleAddContactFolder}
                    >
                      Add Folder
                    </div> */}
                    <div
                      onClick={() => {
                        handleAddButton();
                        console.log("Add Button Clicked for Contact");
                        setType("contact");
                      }}
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        textDecoration: "underline",
                      }}
                    >
                      Add Button
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {isAddButtonVisible && (
            <AddButton
              type={type}
              onReturn={handleReturn}
              fetchData={fetchData}
              buttonData={buttonData}
              updateButton={updateButton}
              sso_cred={sso_cred}
              isgroup={isgroup}
              handleMenus={handleMenus}
            />
          )}
          {showUpdateButton && <UpdateContactButton onReturn={handleReturn} />}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ContactButtons;
