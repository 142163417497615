import { useEffect, useState } from "react";
import crypto from "crypto-js";
import { useAppServices } from "../../../hook/services";

const SSOHandler = () => {
  const AppService = useAppServices()
  const [ssodata, setssodata] = useState('');
  var sso_data_saved = true
  const SSO = {
    navbutton: {
      app_id: '65a7bf4f5f3d2f082f7d330e',
      key: 'cf965064-a325-4590-bc9a-7b7df3647530',
    },
    knowledgebase: {
      app_id: '65a7bf3a6a9bd09ff9e4e8d2',
      key: '3d47bfb0-a21c-4831-950b-c71e496324e1',
    },
    newsfeed: {
      app_id: '65a7c942b6742ec03e79f82f',
      key: '27339a9a-2dad-403d-bf14-e6fa150f8bae',
    },
    dynamicurl: {
      app_id: '65a9402a8b06251c99a21f7a',
      key: 'f3434ae3-089a-46fd-9222-561220f3091b',
    },
  };
  const create_sso_data = async (sso_data, app_id) => {
    // console.log(sso_data, 'sso_datasso_data')
    const data = JSON.parse(sso_data)
    const payload = {
      company_id: data?.companyId,
      app_id: app_id,
      email: data?.companyId,
      userId: data?.userId,
      location_id: data?.activeLocation,
      role: data?.role,
      type: data?.type,
      userName: data?.userName,
    }
    // console.log(payload, 'payloadpayload')

    const { response } = await AppService.webhook.SaveSSOData({
      payload
    })
  }
  const decript_data = (payload, app) => {

    console.log("updated payload",payload)
    const ciphertext = crypto.AES.decrypt(payload, app.key).toString(crypto.enc.Utf8);
    console.log(sso_data_saved, 'sso_data_savedsso_data_saved')

    if (sso_data_saved) {
      sso_data_saved = false
      create_sso_data(ciphertext, app?.app_id)
    }
    setssodata(ciphertext);
  };
  const checkSSO = (app) => {
    // decript_data('U2FsdGVkX1+yjoKtMEFNNjT2yEYVpTy1i1SYg1hfNN5vZaDgW6wPY99PrbYg36ayIkavahGKnifG1taNbFwujKnbhZShD2c5bFgcolAMeoIuRnB+WnH51pt85gsxDeTRBdXpxTa5sGOUqeXp4UgnCJftepKcHdeQOtxRmoYkDuVmhaKrUop+IInEojq52NetAgt1rAQYhIjdm53zwBvfR8izWakLCV8jjWkrGggeEvqvlqTeJyPYrytoCPO7xGGM', { key: "7e015438-095d-45e4-91b5-a290f97653f2", app_id: "6608f26c06203b5387625be2" });

    const key = new Promise((resolve) => {
      window.parent.postMessage({ message: "REQUEST_USER_DATA" }, "*");
      // console.log(
      //   window.parent.postMessage({ message: "REQUEST_USER_DATA" }, "*"),
      //   "test data"
      // );
      const temp = window.addEventListener("message", ({ data }) => {
        if (data.message === "REQUEST_USER_DATA_RESPONSE") {
          const sso = {
            app_id: app.app_id,
            key: app.sso,
          };
          decript_data(data.payload, sso);
        }
      });
    });
    return SSO[app];
  };
  return {
    SSO: ssodata,
    checkSSO: checkSSO,
  };
};
export default SSOHandler;
